import React from 'react';
import { Button } from 'react-bootstrap';
import ThemeLoader from './ThemeLoader';

const FooterSaveButton = ({ disabled = false, text, onClick = () => { }, className }) => {
    return (
        <div className='footer-button d-flex align-items-center flex-column'>
            <Button className={`theme-btn ${className}`} type='submit' onClick={onClick} disabled={disabled}>{disabled ? <ThemeLoader /> : text}</Button>
        </div>
    )
}

export default FooterSaveButton;