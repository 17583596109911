import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MdKeyboardBackspace } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import profile from "../assets/images/profile.png";
import ChooseButton from "../components/ChooseButton";
import CoinsWithDates from "../components/CoinsWithDates.jsx";
import CreditCard from "../components/CreditCard";
import ResellerPopOver from "../components/offcanvasBody/ResellerPopOver.jsx";
import FooterSaveButton from "../components/Theme/FooterButton";
import ThemeInput from "../components/Theme/ThemeInput";
import ThemeOffcanvas from "../components/Theme/ThemeOffcanvas.jsx";
import {
  CoinIcon,
  CopyDoc,
  Premium,
  SearchIcon,
  SettingIcon,
} from "../utils/svg";
import { handleBackButton } from "../utils/helper.js";

function Reseller() {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [active, setActive] = useState(1);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const location = useLocation()


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  const onClick = () => {
    // navigate(ROUTES.bankTransfer);
    setShow(true);
  };

  return (
    <div className="reseller">
      {
        // MARK: Top Bar
      }
      <div className="top-bar flex-custom-space-between ">
        <div className={"flex-custom-space-start"}>
          <MdKeyboardBackspace size={24} onClick={() => handleBackButton(location, navigate)} />
          <h1 className="ms-3">Reseller Account</h1>
        </div>
      </div>

      <div className="content scrollable-content">
        {
          // MARK: Avatar and user image
        }
        <div className="details d-flex align-items-center my-3 mx-3">
          <img
            src={profile}
            className="image-fluid object-fit-contain"
            alt="profile"
          />
          <div className="heading ms-2 d-flex flex-column">
            <p>
              <b>Sufyan </b>
              <span className="mx-1">
                <Premium />
              </span>
            </p>
            <p>
              ID 3023565256
              <span className="mx-1">
                <CopyDoc />
              </span>
            </p>
          </div>
        </div>

        {
          // MARK: Card
        }
        <div className="mx-3">
          <CreditCard />
        </div>

        {
          // MARK: TRANSFER TO - COIN
        }
        <div className="mx-3 my-3 d-flex flex-row justify-content-between ">
          <ThemeInput
            name="userId"
            errors={errors}
            control={control}
            type={"text"}
            isLabel
            label="Transfer to"
            className={"w-100"}
            placeholder={"User ID"}
            rules={{}}
            actionIcon={<SearchIcon />}
          />
        </div>
        <div className="mx-3 my-3 d-flex flex-row justify-content-between ">
          <ThemeInput
            name="transferCoins"
            errors={errors}
            control={control}
            type={"text"}
            isLabel
            label="Transfer Coins"
            className={"w-100"}
            placeholder={"Enter amount in coins"}
            rules={{}}
            actionIcon={<CoinIcon />}
          />
        </div>

        {
          // MARK: Selling and Buying Switch
        }
        <div className="flex-custom-space-center">
          <ChooseButton
            className="mt-0"
            first={"Selling Coins"}
            second={"Buying Coins"}
            active={active}
            setActive={setActive}
          />
        </div>

        {
          // MARK: Search
        }
        <div className="mx-3 my-3 d-flex flex-row justify-content-between ">
          <ThemeInput
            name="userId"
            errors={errors}
            control={control}
            type={"text"}
            isLabel={false}
            className={"w-100"}
            placeholder={"Search..."}
            rules={{}}
            leadingIcon={<SearchIcon />}
            actionIcon={<SettingIcon />}
          />
        </div>

        {
          // MARK: COINS SLOTS
        }
        {Array.from({ length: 8 }).map(() => {
          return (
            <CoinsWithDates date={"05 June"} coins={"Sold 16,394 coins"} />
          );
        })}
      </div>

      {
        // MARK: Transfer Button
      }
      <FooterSaveButton text="Transfer" onClick={onClick} />

      <ThemeOffcanvas
        show={show}
        onHide={() => {
          setShow(!show);
        }}
        body={<ResellerPopOver setShow={setShow} />}
      />
    </div>
  );
}

export default Reseller;
