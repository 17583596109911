import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import profile from "../../assets/images/profile.png";
import { PageNameContext } from "../../context/context";
import { ROUTES } from "../../utils/app-constants";
import { CopyDoc, OrangeCoinIcon } from "../../utils/svg";

function ResellerPopOver({ setShow }) {
  const [page, setPage] = useContext(PageNameContext);
  const navigate = useNavigate();

  return (
    <div className="reseller-popover-body">
      <div
        className="devider"
        onClick={() => {
          setShow(false);
        }}
      >
        <span className="line"></span>
      </div>
      <h2>Please confirm the following transfer information</h2>

      <div className="details d-flex justify-content-center align-items-center my-3 mx-3">
        <img
          src={profile}
          className="image-fluid object-fit-contain"
          alt="profile"
        />
        <div className="heading ms-2 d-flex flex-column">
          <p>
            <b>Gojo Satoru Team </b>
          </p>
          <p>
            ID 3023565256
            <span className="mx-1">
              <CopyDoc />
            </span>
          </p>
        </div>
      </div>

      <div className="flex-custom-space-center flex-column">
        <div className="coin d-flex flex-column align-items-center justify-content-center mt-3">
          <p>Transfer Coins </p>
          <div className="amount-coin flex-custom-space-center">
            <OrangeCoinIcon />
            <p className="ms-1">12,457,567</p>
          </div>
        </div>
        <div className="buttons d-flex mt-5">
          <Button
            className="cnfrm-btn"
            onClick={() => {
              setPage("My Accounts");
              navigate(ROUTES.withdrawlSuccess);
            }}
          >
            Confirm
          </Button>
          <Button className="cancel-btn" onClick={() => setShow(false)}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ResellerPopOver;
