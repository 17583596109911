import React from 'react'
import { Form } from 'react-bootstrap'

const SelectRadio = ({ selected, setSelected, className }) => {
    return (
        <div className={`radio-component w-100 ${className}`}>
            <p className='label-input'>Choose</p>
            <div className='select-radio d-flex justify-content-around'>
                <Form.Check
                    inline
                    label="Exchange"
                    name="group1"
                    type={"radio"}
                    id={`1`}
                    checked={selected == "1"}
                    onChange={selected == "1" ? () => { } : setSelected}
                />
                <Form.Check
                    inline
                    label="Withdrawl"
                    name="group1"
                    type={"radio"}
                    id={`2`}
                    checked={selected == "2"}
                    onChange={selected == "2" ? () => { } : setSelected}
                />
            </div>
        </div>
    )
}

export default SelectRadio
