import React from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'

const TopBarCustom = ({ text, onClick = () => { }, children }) => {
    return (
        <div className="top-bar-custom flex-custom-space-between ">
            <div className={"flex-custom-space-start"}>
                <MdKeyboardBackspace size={24} onClick={onClick} />
                <h1 className="ms-3">{text}</h1>
            </div>
            {children}
        </div>
    )
}

export default TopBarCustom
