import React from 'react'
import { CoinYellow, DiamondYellow } from '../utils/svg'

function DiamondCoinBox({ className, amount, coins, onClick }) {
    return (
        <div className={`diamond-box d-flex flex-column ${className}`} onClick={onClick}>
            <div className="amount d-flex">
                <DiamondYellow />
                {amount}K
            </div>
            <div className="usd d-flex align-items-center flex-row">

                <span className="d-flex me-1"><CoinYellow /></span>
                {coins}</div>
        </div>
    )
}

export default DiamondCoinBox
