import React from "react";
import { CopyDoc, Premium } from "../utils/svg";

function RequestViewData({ imageUrl, onClick, className, option }) {
  return (
    <div className={`d-flex justify-content-between align-items-center w-100 request-data ${className}`}>
      <div className=" d-flex align-items-center justify-content-between content">
        <div className="d-flex detail">
          <img
            src={imageUrl}
            className="image-fluid object-fit-contain"
            alt="profile"
          />
          <div className="data d-flex flex-column ms-2">
            <div className="d-flex name-text-bold gap-1"><p className="">Gojo Satoru Team</p><Premium /></div>
            <div className="d-flex sub-heading-text gap-2"><p>ID 3023565256</p><CopyDoc /></div>
          </div>
        </div>
        {/* <img
          src={imageUrl}
          className="image-fluid object-fit-contain"
          alt="profile"
        />
        <div className="heading ms-2 d-flex flex-column">
          <div>
            <h2>Sufyan </h2>
            <span className="mx-1">
              <Premium />
            </span>
          </div>
          <p>
            ID 3023565256
            <span className="mx-1">
              <CopyDoc />
            </span>
          </p>
        </div> */}
      </div>
      <div className="">
        {option}
      </div>
    </div>
  );
}

export default RequestViewData;
