import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ChooseButton from "../components/ChooseButton";
import CurrentBalance from "../components/CurrentBalance";
import DemondBox from "../components/DemondBox";
import StarText from "../components/StarText";
import FooterSaveButton from "../components/Theme/FooterButton";
import TopWalletHeader from "../components/TopWalletHeader";
import { ROUTES } from "../utils/app-constants";
import { Diamond, RedCapsule, RedSeprator } from "../utils/svg";
import SelectRadio from "../components/SelectRadio";
import WithdrawlBody from "../components/offcanvasBody/WithDrawlBody";
import ThemeOffcanvas from "../components/Theme/ThemeOffcanvas";

const WithdrawlBundle = () => {
    const navigate = useNavigate()

    const [show, setShow] = useState(false)
    const [activeType] = useState(1)
    const [selected, setSelected] = useState(null)
    const [selectedToggle, setSelectedToggle] = useState(2)

    const WithdrawlData = [
        { id: 1, amount: 40, USD: 50 },
        { id: 2, amount: 100, USD: 150 },
        { id: 3, amount: 200, USD: 300 },
        { id: 4, amount: 500, USD: 750 },
        { id: 5, amount: 1000, USD: 150 },
        { id: 6, amount: 1500, USD: 250 },
        { id: 7, amount: 2000, USD: 300 },
        { id: 8, amount: 500, USD: 750 },
        { id: 9, amount: 1000, USD: 1500 },
        { id: 17, amount: 200, USD: 300 },
        { id: 18, amount: 500, USD: 750 },
        { id: 19, amount: 100, USD: 1500 },
    ]

    const setActiveType = () => {
        window?.ReactNativeWebView?.postMessage('coins');
    }


    const setActive = () => {
        navigate(ROUTES.withdrawlCustom)
    }

    const handleToggle = () => {
        navigate(ROUTES.exchange)
    }

    return (
        <div className="withdrawl">
            <div className="container">
                <TopWalletHeader page={"Withdrawl"} className={""} />
            </div>
            <div className="container inner pt-3 pb-3">
                <div className="flex-custom-space-center w-100 mb-3">
                    <ChooseButton className="coins-choose-btn" first={"Coin"} second={"Diamond"} active={activeType} setActive={setActiveType} />
                </div>
                <CurrentBalance className="balance mt-2" text={
                    <div className="diamond-count flex-custom-space-start">
                        <Diamond />
                        12,457,567
                    </div>
                } heading={"Current Balance"} />
                <div className="flex-custom-space-center mt-3">
                    <RedSeprator />
                </div>
                <div className="select-toggle d-flex mt-3">
                    <RedCapsule />
                    <SelectRadio className="ms-2" selected={selectedToggle} setSelected={handleToggle} />
                </div>
                {/* <div className="heading mt-4 flex-custom-space-start">
                    <RedCapsule />
                    <h2>Withdrawal</h2>
                </div> */}
                <div className="flex-custom-space-center">
                    <ChooseButton className="mt-3" first={"Choose"} second={"Custom Amt"} active={0} setActive={setActive} />
                </div>
                <div className="mt-2 boxes flex-custom-space-center mt-4">
                    {WithdrawlData?.map((dt) => (
                        <DemondBox className={selected?.id === dt?.id && "active"} key={dt?.id} amount={dt?.amount} USD={dt?.USD} onClick={() => setSelected(dt)} />
                    ))}
                </div>
                <StarText text={"Company Withdrawal"} className={"reseller-text"} />
                <div className="agree mt-4">
                    <Form>
                        <div key={`default`} className="mb-3">
                            <Form.Check
                                type={"checkbox"}
                                // id={`default-${type}`}
                                label={<div className={"agree-text"}>I agree to the <Link to="/dashboard">Terms & Conditions</Link> by clicking confirm.</div>}
                            />
                        </div>
                    </Form>
                </div>
            </div>
            <FooterSaveButton text={"Withdraw"} onClick={() => setShow(true)} />
            <ThemeOffcanvas show={show} onHide={() => { setShow(!show) }} body={<WithdrawlBody setShow={setShow} />} />
        </div>
    )
}

export default WithdrawlBundle;