import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { MdKeyboardBackspace } from "react-icons/md";
import profile from "../assets/images/profile.png";
import ChooseButton from "../components/ChooseButton";
import RequestViewData from "../components/RequestViewData";
import ThemeInput from "../components/Theme/ThemeInput";
import { SearchIcon } from "../utils/svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../utils/app-constants";

function MyRequest() {
  const navigate = useNavigate()

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [active, setActive] = useState(0);

  const handleAdminApplication = async () => {
    navigate(ROUTES.adminApplication)
  };
  const handleAgencyApplication = async () => {
    navigate(ROUTES.agencyApplication)
  };

  const handleBackButton = () => {
    navigate(-1)
  }


  return (
    <div className="my-request bg-pink">
      {
        // MARK: Top Bar
      }
      <div className="top-bar flex-custom-space-between">
        <div className={"flex-custom-space-start"} onClick={handleBackButton}>
          <MdKeyboardBackspace size={24} />
        </div>
        <h1 className="mx-auto">My Request</h1>
      </div>

      <div className="scrollable-content container">
        {
          // MARK: Selling and Buying Switch
        }
        <div className="flex-custom-space-center">
          <ChooseButton
            className="mt-3 choose-btn-req"
            first={"Admin Apply"}
            second={"Agency Apply"}
            active={active}
            setActive={setActive}
          />
        </div>

        {
          // MARK: Search
        }
        <div className="mt-3 d-flex flex-row justify-content-between ">
          <ThemeInput
            name="userId"
            errors={errors}
            control={control}
            type={"text"}
            isLabel={false}
            className={"w-100 input-search-for-theme-pink"}
            placeholder={"Search something..."}
            leadingIcon={<SearchIcon />}
          />
        </div>

        {
          // MARK: DATA
        }

        <div className="mt-3">
          {Array.from({ length: 10 }).map(() => {
            return <RequestViewData className={"mb-3"} imageUrl={profile} option={
              <>
                <div className="view-btn" onClick={!active ? handleAdminApplication : handleAgencyApplication}>
                  View Data
                </div>
              </>
            } />;
          })}
        </div>
      </div>
    </div>
  );
}

export default MyRequest;
