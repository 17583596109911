import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import FooterSaveButton from '../components/Theme/FooterButton';
import ThemeFileUpload from '../components/Theme/ThemeFileUpload';
import ThemeInput from '../components/Theme/ThemeInput';
import TopBarCustom from '../components/TopBarCustom';
import { ROUTES, validation, validationText } from '../utils/app-constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleBackButton } from '../utils/helper';

const BecomeHost = () => {
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm({ mode: "onChange" });
    
    const navigate = useNavigate();
    const location = useLocation()

    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState(null)
    const [phone, setPhone] = useState(null)

    const onSubmit = (data) => {
        setLoading(true)

        setTimeout(() => {
            setLoading(false)
            navigate(ROUTES.hostSuccess)
        }, 1000)
    };

    const handleOtp = () => {
        console.log(otp);
    }
    const onClick = () => {
        navigate(-1)
    }



    return (
        <div className='bg-light-pink'>
            <TopBarCustom text={"Become Host"} onClick={() => handleBackButton(location, navigate)} />
            <div className="bank-transfer become-seller pt-3 pb-5">
                <Form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="container">
                        <Row>
                            <Col xs={12}>
                                <label className='special-label'>Upload Images</label>
                            </Col>

                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadImage"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Upload Image"
                                    // className={"mt-3"}
                                    icon={require('./../assets/images/gallery-add.png')}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: validationText.general,
                                    //     }
                                    // }}
                                />
                            </Col>
                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadVideo"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Upload Video"
                                    // className={"mt-3"}
                                    icon={require('./../assets/images/video.png')}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: validationText.general,
                                    //     }
                                    // }}
                                />
                            </Col>
                        </Row>
                        <Row className={`mt-3`}>
                            <Col xs={12}>
                                <label className='special-label'>Upload CNIC*</label>
                            </Col>
                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadFrontCnic"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Front"
                                    icon={require('./../assets/images/gallery-add.png')}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: validationText.general,
                                    //     }
                                    // }}
                                />
                            </Col>
                            <Col xs={6}>
                                <ThemeFileUpload
                                    name="uploadBackCnic"
                                    errors={errors}
                                    control={control}
                                    isLabel
                                    label="Back"
                                    icon={require('./../assets/images/gallery-add.png')}
                                    // rules={{
                                    //     required: {
                                    //         value: true,
                                    //         message: validationText.general,
                                    //     }
                                    // }}
                                />
                            </Col>
                        </Row>

                        <ThemeInput
                            name="officialId"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Agency Code (provided by agency)"
                            className={"mt-3"}
                            placeholder={"Please Input the Code"}
                            // rules={{
                            //     required: {
                            //         value: true,
                            //         message: validationText.general,
                            //     },
                            //     minLength: {
                            //         value: validation.nameMin,
                            //         message: validationText.nameMin,
                            //     },
                            //     maxLength: {
                            //         value: validation.nameMax,
                            //         message: validationText.nameMax,
                            //     },
                            // }}
                        />

                        <ThemeInput
                            name="superAdminName"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="Full Name"
                            className={"mt-3"}
                            placeholder={"Type here"}
                            // rules={{
                            //     required: {
                            //         value: true,
                            //         message: validationText.general,
                            //     },
                            //     minLength: {
                            //         value: validation.nameMin,
                            //         message: validationText.nameMin,
                            //     },
                            //     maxLength: {
                            //         value: validation.nameMax,
                            //         message: validationText.nameMax,
                            //     },
                            // }}
                        />

                        <PhoneInput
                            className="mt-3"
                            specialLabel="Contact Number"
                            country={'pk'}
                            value={phone}
                            onChange={(e) => setPhone(e)}
                            placeholder="Type here"
                        />

                        <ThemeInput
                            name="eMail"
                            errors={errors}
                            control={control}
                            type={"text"}
                            isLabel
                            label="E-Mail"
                            className={"mt-3"}
                            placeholder={"Type here"}
                            // rules={{
                            //     required: {
                            //         value: true,
                            //         message: validationText.general,
                            //     },
                            //     maxLength: {
                            //         value: validation.emailMax,
                            //         message: validationText.nameemailMax,
                            //     },
                            // }}
                        />
                        <div className='gender mt-3'>
                            <label className='special-label'>Gender</label>
                            <div className='option d-flex'>
                                <div className='first'>Male</div>
                                <div className='second'>Female</div>
                            </div>
                        </div>
                        <div className='gender type mt-3'>
                            <label className='special-label'>Type</label>
                            <div className='option d-flex'>
                                <div className='first'>Voice Live</div>
                                <div className='second'>Video Live</div>
                            </div>
                        </div>
                    </div>
                    <FooterSaveButton disabled={loading} text={"Submit"} />
                </Form>
            </div>
        </div>
    )
}

export default BecomeHost