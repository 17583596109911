import React, { useState } from 'react';
import { Button, Tab, Tabs } from "react-bootstrap";
import MyAdmins from '../components/ConAdminCenter/MyAdmins';
import MyAgency from '../components/ConAdminCenter/MyAgency';
import UserCard from '../components/UserCard';
import AppLayout from '../Layouts/AppLayout';
import TopBarCustom from '../components/TopBarCustom';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../utils/app-constants';
import ThemeRequestsBtn from '../components/Theme/ThemeRequestsBtn';
import { handleBackButton } from '../utils/helper';

const SuperAdminCenter = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const [adminTab, setAdminTab] = useState('my-admins');



    const handleViewRequest = () => {
        navigate(ROUTES.myRequest)
    }

    return (
        <div className='bg-pink-medium'>
            <TopBarCustom text={"Con Admin Center"} onClick={() => handleBackButton(location, navigate)} >
                <ThemeRequestsBtn handleViewRequest={handleViewRequest} text={"20 Requests"} />
            </TopBarCustom>
            <div className="container pt-3 pb-3 super-admin-center">
                <UserCard
                    title={`Admin Name Here`}
                    description={`ID 3023565256`}
                    image={require('./../assets/images/person-1.png')}
                    className={`mb-3 border-0`}
                />

                <div className="mb-3">
                    <Tabs activeKey={adminTab} onSelect={(k) => setAdminTab(k)} className="admin-tabs">
                        <Tab eventKey="my-admins" title="My Admins ( 5 )">
                            <MyAdmins />
                        </Tab>
                        <Tab eventKey="my-agency" title="My Agency ( 3 )">
                            <MyAgency />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default SuperAdminCenter
