import React, { useState } from 'react';
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import MyAdmins from '../components/ConAdminCenter/MyAdmins';
import MyAgency from '../components/ConAdminCenter/MyAgency';
import UserCard from '../components/UserCard';
import AppLayout from '../Layouts/AppLayout';
import TopBarCustom from '../components/TopBarCustom';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrentDate from '../components/CurrentDate';
import IncomeCard from '../components/IncomeCard';
import SearchBar from '../components/SearchBar';
import TeamCard from '../components/TeamCard';
import { ROUTES } from '../utils/app-constants';
import ThemeRequestsBtn from '../components/Theme/ThemeRequestsBtn';
import { handleBackButton } from '../utils/helper';

const AdminCenter = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [adminTab, setAdminTab] = useState('my-admins');

    const handleViewAgency = () => {
        navigate(ROUTES.agencyDetails)
    }

    const handleViewRequest = () => {
        navigate(ROUTES.agencyApplication)
    }

    return (
        <div className='bg-light-pink'>
            <TopBarCustom text={"Admin Center"} onClick={() => handleBackButton(location, navigate)}>
                <ThemeRequestsBtn handleViewRequest={handleViewRequest} text={"20 Requests"} />
            </TopBarCustom>
            <div className="container pt-3 pb-3 super-admin-center">
                <UserCard
                    title={`Admin Name Here`}
                    description={`ID 3023565256`}
                    image={require('./../assets/images/person-1.png')}
                    className={`mb-3 border-0`}
                />
                <div className="py-3">
                    <div className="mb-3">
                        <CurrentDate />
                    </div>

                    <div className="mb-3">
                        <Row className="gx-3">
                            <Col xs={6}>
                                <IncomeCard
                                    bgColor="#FFEDD5"
                                    title="45780"
                                    description="Total Income Month"
                                    image={require('../assets/images/money-send.png')}
                                    className="border-0"
                                />
                            </Col>
                            <Col xs={6}>
                                <IncomeCard
                                    bgColor="#FEF3C7"
                                    title="45780"
                                    description="Daily Income"
                                    image={require('../assets/images/wallet.png')}
                                    className="border-0"
                                />
                            </Col>
                        </Row>
                    </div>
                    <h4 className='fw-bold mb-2'>{"My Agency's"}</h4>
                    <div className="mb-3">
                        <SearchBar />
                    </div>
                    <div className="mb-3 pb-2">
                        {[1, 1, 1]?.map(item => {
                            return <TeamCard
                                bgColor="#fff"
                                verified
                                shadow
                                title='Gojo Satoru Team'
                                description='ID 3023565256'
                                image={require('../assets/images/person-1.png')}
                                className="mb-3 border-0 cursor-pointer"
                                authorType="Admin"
                                authorName="Kathykatie"
                                agencyType="Agency code:"
                                agencyName="345"
                                onClick={handleViewAgency}
                            />
                        })}

                        <TeamCard
                            type="danger"
                            bgColor="#fff"
                            verified
                            shadow
                            title='Gojo Satoru Team'
                            description='ID 3023565256'
                            image={require('../assets/images/person-1.png')}
                            className="mb-3"

                            authorType="Admin"
                            authorName="Kathykatie"

                            agencyType="Agency code:"
                            agencyName="345"
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AdminCenter
