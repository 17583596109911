import React, { useState } from 'react'
import ChooseButton from '../components/ChooseButton'
import TopBarCustom from '../components/TopBarCustom'
import HistoryCard from '../components/HistoryCard'
import { Coin, DownIcon, UpIcon } from '../utils/svg'
import { useNavigate } from 'react-router-dom'

const WalletHistory = () => {
    const [active, setActive] = useState(1)
    const data = Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        text: "Shield",
        subtext: "Shield",
        amount: "2,434",
        date: "12-03-24 at 11:22PM"
    }));
    const navigate = useNavigate()

    const handleBackButton = () => {
        navigate(-1)
    }
    return (
        <div className='wallet-history pb-3'>
            <TopBarCustom text={"Wallet History"} onClick={handleBackButton} />
            <div className='inner container'>
                <div className="flex-custom-space-center choose-btn-comp mt-4">
                    <ChooseButton className="mt-3 choose-btn" first={"Purchase"} second={"Withdrawal"} active={active} setActive={setActive} />
                </div>
                {data?.map((dt) => (
                    <div key={dt?.id} className='coins-card mt-3'>
                        <HistoryCard image={<Coin />} signal={active == 1 ? <DownIcon /> : <UpIcon />} dt={dt} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default WalletHistory
