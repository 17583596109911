import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../Layouts/AppLayout";
import AccountBox from "../components/AccountBox";
import FooterSaveButton from "../components/Theme/FooterButton";
import ThemeLoader from "../components/Theme/ThemeLoader";
import { ROUTES } from "../utils/app-constants";
import { FaPlus } from "react-icons/fa6";
import TopBarCustom from "../components/TopBarCustom";

const MyAccount = () => {
    const [loading, setLoading] = useState(true)
    const [activeId, setActiveId] = useState(2); // Global active state

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])
    const onClick = () => {
        navigate(ROUTES.bankTransfer)
    }
    const handleBackButton = () => {
        navigate(-1)
    }
    return (
        <div className="bg-light-pink">
            <TopBarCustom text={"My Accounts"} onClick={handleBackButton} />
            <div className="my-account container pt-3 pb-3">
                {loading ? (
                    <ThemeLoader className={"theme-center-loader"} />
                ) : (
                    Array.from({ length: 20 }).map((_, i) => (
                        <AccountBox
                            key={i}
                            id={i}
                            group={"group1"}
                            activeId={activeId}
                            setActiveId={setActiveId} // Pass global state and updater
                            className={"mb-3"}
                        />
                    ))
                )}
                <br></br>
                <br></br>
            </div>
            <FooterSaveButton text={
                <>
                    <FaPlus /> Add Account
                </>
            } onClick={onClick} />
        </div>
    )
}

export default MyAccount;