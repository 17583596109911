import React from "react";
import { Button } from "react-bootstrap";

function CreditCard() {
  return (
    <div className="mx-auto credit-card bg-1 d-flex justify-content-between flex-column">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <p>FOGO Coins</p>
          <p className="fs-2 fw-bold">12,457</p>
        </div>
        <Button className="seller-button">Country Seller</Button>
      </div>
      <div>
        <Button className="top-up-button" type="submit">
          Top Up
        </Button>
      </div>
    </div>
  );
}

export default CreditCard;
