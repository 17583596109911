import React from "react";

function CoinsWithDates({ date, coins }) {
  return (
    <div className="mx-3 d-flex flex-row justify-content-between align-items-center bg-white shadow-sm rounded-3 p-3 my-2">
      <div className="d-flex align-items-center gap-2">
        <div className="date">{date}</div>
        <p className="fs-6 fw-bold">{coins}</p>
      </div>
    </div>
  );
}

export default CoinsWithDates;
