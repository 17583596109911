import React from 'react'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import FooterSaveButton from '../components/Theme/FooterButton'
import { DiamondSelling, WithDrawlSuccess } from '../utils/svg'

const SuccessfullWithDrawl = () => {
    const navigate = useNavigate();

    return (
        <div className='bg-light-pink'>
            <div className='withdrawl-page'>
                <div className="top-bar flex-custom-space-between ">
                    <div className={"flex-custom-space-start"}>
                        <MdKeyboardBackspace size={24} onClick={() => { }} />
                        <h1 className="ms-3">My Accounts</h1>
                    </div>
                </div>
                <div className='withdrawl-success container d-flex flex-column align-items-center pt-5 pb-3'>
                    <div className=''>
                        <WithDrawlSuccess />
                    </div>
                    <h1 className='mt-2'>Withdraw Successful</h1>
                    <div className='selling-div flex-custom-space-between mt-5'>
                        <div className='selling d-flex flex-column'>
                            <DiamondSelling />
                            <p>Selling Diamonds</p>
                            <span>12,457,567</span>
                        </div>
                        <div className='receiving flex-custom-space-center flex-column'>
                            <div className='d-flex flex-column align-item-start'>
                                <p>Receiving</p>
                                <p>Amount</p>
                            </div>
                            <span>= $200</span>
                        </div>
                    </div>
                    <div className='w-100 detail-card mt-3'>
                        <div className='main-heading d-flex justify-content-start align-items-start w-100'>Withdrawal Details</div>
                        <div className='details w-100 d-flex mt-3'>
                            <div className='headings d-flex flex-column align-items-start'>
                                <p>Order ID: </p>
                                <p>Withdrawal amount :</p>
                                <p>Date & Time:</p>
                                <p>Account Number:</p>
                            </div>
                            <div className='text d-flex flex-column align-items-end'>
                                <p>51581151561811566691</p>
                                <p>$200</p>
                                <p>14 Nov  2024, 06:32 PM </p>
                                <p>ABC</p>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterSaveButton className="theme-lite-btn" text={"Back to Home"} onClick={() => navigate(-1)} />
            </div>
        </div>
    )
}

export default SuccessfullWithDrawl
