import React from "react";
import { Image } from "react-bootstrap";

const Host = (props) => {
    const {
        title,
        description,
        image
    } = props

    return (
        <>
            <div className="host-container">
                <h2 className="host-title">{title}</h2>
                <Image className="host-image" src={image} />
                <span className="host-description">{description}</span>
            </div>
        </>
    )
}
export default Host;