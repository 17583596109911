import React, { forwardRef, useState } from "react";
import { Image } from "react-bootstrap";
import DatePicker from "react-datepicker";
import calendarIcon from "./../assets/images/calendar.png"; // Import the image

const CurrentDate = () => {
    const [startDate, setStartDate] = useState(new Date());
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div onClick={onClick} ref={ref} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
            <img src={calendarIcon} alt="Calendar Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
            {/* <span>{value || "Select a date"}</span> */}
        </div>
    ));
    return (
        <>
            <div className="current-date-container">
                <label className="current-date">25 July, 2024</label>
                {/* <Image src={require('./../assets/images/calendar.png')} className="label-input-file-image" /> */}
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<CustomInput />}
                    dateFormat="MMMM d, yyyy" // Optional formatting
                />
            </div>
        </>
    )
}
export default CurrentDate;