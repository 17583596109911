export function Edit() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 13.3333H14M2 13.3333H3.11636C3.44248 13.3333 3.60554 13.3333 3.75899 13.2965C3.89504 13.2638 4.0251 13.2099 4.1444 13.1368C4.27895 13.0544 4.39425 12.9391 4.62486 12.7085L13 4.33331C13.5523 3.78103 13.5523 2.8856 13 2.33331C12.4477 1.78103 11.5523 1.78103 11 2.33331L2.62484 10.7085C2.39424 10.9391 2.27894 11.0544 2.19648 11.1889C2.12338 11.3082 2.0695 11.4383 2.03684 11.5743C2 11.7278 2 11.8908 2 12.217V13.3333Z"
        stroke="#344054"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}


export function CoinYellow() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2388_24138)">
        <path d="M13.7787 12.7536C16.7974 9.00838 16.9315 4.10797 14.0783 1.80827C11.2251 -0.491443 6.46499 0.680401 3.4463 4.42565C0.427603 8.1709 0.293459 13.0713 3.14668 15.371C5.99989 17.6707 10.76 16.4989 13.7787 12.7536Z" fill="#FFA903" />
        <path d="M14.0099 1.80931C12.8771 0.926898 11.449 0.512449 10.0199 0.651307C11.1223 0.719058 12.1774 1.12322 13.043 1.80931C15.896 4.10911 15.7622 9.00931 12.7436 12.7545C10.9016 15.0399 8.41156 16.3647 6.10156 16.5297C8.66056 16.7214 11.6075 15.363 13.7102 12.7545C16.7288 9.00931 16.8632 4.10881 14.0099 1.80931Z" fill="white" />
        <path d="M14.9742 2.12522C14.824 2.00397 14.668 1.89003 14.5068 1.78382C14.4961 1.77867 14.4851 1.77416 14.4738 1.77032C14.4222 1.74476 14.3644 1.73416 14.307 1.73972C14.2481 1.74566 14.1927 1.77037 14.1489 1.81022C14.1392 1.81657 14.1297 1.82348 14.1207 1.83092C14.115 1.83692 14.1132 1.84502 14.1081 1.85132C14.103 1.85762 14.0958 1.86152 14.091 1.86842C14.086 1.87915 14.0816 1.89017 14.0778 1.90142C14.052 1.95361 14.0413 2.01206 14.0472 2.07002C14.0498 2.08868 14.0541 2.10707 14.0601 2.12492C14.0706 2.16267 14.0902 2.19728 14.1171 2.22572C14.1236 2.23583 14.1306 2.24555 14.1381 2.25482C16.6215 4.54562 16.3626 9.07562 13.5486 12.5673C10.638 16.1778 6.05764 17.3313 3.33844 15.1392C3.32082 15.1276 3.30202 15.1178 3.28234 15.1101L3.26584 15.1014C3.23718 15.0894 3.20681 15.0819 3.17584 15.0792L3.15184 15.0771C3.08781 15.0756 3.02523 15.0964 2.97484 15.1359C2.96472 15.1412 2.95491 15.147 2.94544 15.1533C2.93944 15.1584 2.93734 15.1656 2.93224 15.1713C2.92744 15.1764 2.92084 15.1788 2.91634 15.1842C2.91118 15.1931 2.90648 15.2022 2.90224 15.2115C2.8877 15.2325 2.87591 15.2553 2.86714 15.2793C2.86426 15.2889 2.86186 15.2986 2.85994 15.3084C2.85291 15.3376 2.85028 15.3676 2.85214 15.3975L2.85484 15.4149C2.85925 15.4475 2.86898 15.4791 2.88364 15.5085L2.89174 15.522C2.90066 15.5414 2.91162 15.5598 2.92444 15.5769C3.1357 15.8104 3.36501 16.0268 3.61024 16.2243C4.75661 17.1311 6.1811 17.6138 7.64254 17.5908C10.2195 17.5908 13.0095 16.2468 15.0009 13.7763C18.1341 9.88922 18.1221 4.66232 14.9742 2.12522Z" fill="#F1B31C" />
        <path d="M4.5625 11.6148C4.5844 11.5452 4.7455 10.9506 4.7668 10.8813C6.5422 10.9128 6.6298 10.8537 6.8935 9.9786C7.3915 8.3262 7.5367 8.0304 8.035 6.378C8.2099 5.7984 8.179 5.6718 7.348 5.5134L6.8419 5.4201C6.8608 5.3589 6.9742 4.7976 6.9931 4.7367C8.21834 4.73749 9.44234 4.65842 10.6573 4.5L9.0076 10.2C8.7502 11.0898 8.782 11.1612 10.4866 11.4906C10.4668 11.5608 10.3066 12.156 10.2871 12.2265L4.5625 11.6148Z" fill="white" />
        <path d="M14.339 2.03125C14.491 2.13145 14.6392 2.23965 14.7836 2.35585C17.8076 4.79305 17.7992 9.82075 14.7653 13.5849C11.7314 17.349 6.82013 18.4254 3.79613 15.9882C3.56313 15.8006 3.34526 15.5949 3.14453 15.3732" stroke="#1D2939" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M13.7787 12.7536C16.7974 9.00838 16.9315 4.10797 14.0783 1.80827C11.2251 -0.491443 6.46499 0.680401 3.4463 4.42565C0.427603 8.1709 0.293459 13.0713 3.14668 15.371C5.99989 17.6707 10.76 16.4989 13.7787 12.7536Z" stroke="#1D2939" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M13.7765 12.7548L14.7623 13.5873M12.7394 13.8783L13.6082 14.8122M11.6417 14.7915L12.3551 15.7881M10.4375 15.5949L10.9991 16.5981M14.5754 11.6328L15.6221 12.357" stroke="#1D2939" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M4.5625 11.6148C4.5844 11.5452 4.7455 10.9506 4.7668 10.8813C6.5422 10.9128 6.6298 10.8537 6.8935 9.9786C7.3915 8.3262 7.5367 8.0304 8.035 6.378C8.2099 5.7984 8.179 5.6718 7.348 5.5134L6.8419 5.4201C6.8608 5.3589 6.9742 4.7976 6.9931 4.7367C8.21834 4.73749 9.44234 4.65842 10.6573 4.5L9.0076 10.2C8.7502 11.0898 8.782 11.1612 10.4866 11.4906C10.4668 11.5608 10.3066 12.156 10.2871 12.2265L4.5625 11.6148Z" stroke="#1D2939" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2388_24138">
          <rect width="18" height="18" fill="white" transform="translate(0.167969)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function VerifyOTPIcon() {
  return (
    <svg
      width="151"
      height="124"
      viewBox="0 0 151 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.1286 80.4495C125.826 80.4495 151 117.746 151 76.1452C151 34.5445 117.197 0.820389 75.5 0.820389C33.8025 0.820389 0 34.5445 0 76.1452C0 117.746 42.4311 80.4495 84.1286 80.4495Z"
        fill="url(#paint0_linear_1_7646)"
      />
      <path
        d="M101.38 123.549H50.7694L48 42.4098H98.6111L101.38 123.549Z"
        fill="url(#paint1_linear_1_7646)"
      />
      <path
        d="M103.908 121.029H53.2965L50.5272 39.8898H101.138L103.908 121.029Z"
        fill="url(#paint2_linear_1_7646)"
      />
      <path
        opacity="0.3"
        d="M103.908 121.029H53.2965L50.5272 39.8898H101.138L103.908 121.029Z"
        fill="white"
      />
      <path
        d="M86.9538 73.2789C92.3728 67.7837 92.1423 58.798 86.4389 53.2089C80.7354 47.6198 71.7189 47.5437 66.2999 53.0389C60.8809 58.5342 61.1114 67.5198 66.8149 73.1089C72.5183 78.698 81.5348 78.7741 86.9538 73.2789Z"
        fill="url(#paint3_linear_1_7646)"
      />
      <path
        d="M88.3751 63.1625C88.4492 65.096 88.0259 67.0163 87.1454 68.7407C86.1963 70.5986 84.747 72.1564 82.9598 73.2396C81.1727 74.3227 79.1182 74.8883 77.0268 74.8731C74.8851 74.8608 72.7843 74.2878 70.9347 73.2115C69.085 72.1353 67.5515 70.5935 66.4875 68.7407C65.4968 67.0423 64.9433 65.1259 64.8765 63.1625C64.8108 61.64 65.0562 60.1202 65.5979 58.6952C66.1396 57.2703 66.9663 55.97 68.0277 54.8734C69.0891 53.7767 70.3631 52.9067 71.7722 52.3161C73.1813 51.7254 74.6962 51.4266 76.2248 51.4376C79.3739 51.4598 82.3933 52.6904 84.6559 54.8739C86.9186 57.0573 88.2505 60.0257 88.3751 63.1625Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M87.1447 68.7384C86.1956 70.5963 84.7463 72.1542 82.9591 73.2373C81.172 74.3204 79.1175 74.886 77.026 74.8708C74.8844 74.8585 72.7836 74.2855 70.934 73.2093C69.0843 72.133 67.5508 70.5913 66.4868 68.7384C69.4995 66.6924 73.0635 65.6047 76.7088 65.6188C80.4178 65.6244 84.0446 66.7085 87.1447 68.7384Z"
        fill="url(#paint4_linear_1_7646)"
      />
      <path
        opacity="0.6"
        d="M80.2857 62.8746C82.2784 60.854 82.1936 57.5499 80.0964 55.4947C77.9992 53.4396 74.6837 53.4116 72.6911 55.4322C70.6985 57.4529 70.7832 60.757 72.8804 62.8122C74.9776 64.8673 78.2931 64.8953 80.2857 62.8746Z"
        fill="url(#paint5_linear_1_7646)"
      />
      <path
        d="M93.7426 91.6461H61.4548L61.2374 85.2508H93.5216L93.7426 91.6461Z"
        fill="white"
      />
      <path
        d="M94.1307 103.076H61.8465L61.6255 96.6804H93.9133L94.1307 103.076Z"
        fill="white"
      />
      <path
        d="M81.6711 97.5601L73.8905 98.626C73.8162 98.6349 73.7446 98.6589 73.6801 98.6967C73.6156 98.7345 73.5596 98.7852 73.5158 98.8457C73.4719 98.9061 73.4411 98.9749 73.4252 99.0478C73.4094 99.1207 73.4088 99.196 73.4236 99.2691L74.443 104.467C74.4757 104.62 74.5652 104.755 74.6934 104.844C74.8217 104.934 74.9792 104.972 75.1344 104.95L82.915 103.884C82.9894 103.876 83.0611 103.852 83.1257 103.814C83.1904 103.776 83.2465 103.725 83.2907 103.665C83.3348 103.605 83.366 103.536 83.3823 103.463C83.3986 103.39 83.3997 103.315 83.3854 103.241L82.3625 98.0433C82.3298 97.8906 82.2403 97.756 82.112 97.6663C81.9838 97.5767 81.8262 97.5387 81.6711 97.5601ZM79.4364 102.826L78.0107 103.014L77.8681 101.337C77.7214 101.274 77.5922 101.176 77.4916 101.053C77.391 100.929 77.3221 100.783 77.2907 100.626C77.2645 100.506 77.264 100.381 77.2892 100.26C77.3143 100.139 77.3646 100.024 77.4368 99.9241C77.509 99.8236 77.6015 99.7394 77.7083 99.6768C77.8151 99.6142 77.9339 99.5746 78.057 99.5605C78.314 99.5234 78.5755 99.5855 78.7881 99.7341C79.0007 99.8826 79.1484 100.106 79.2011 100.36C79.2329 100.509 79.2242 100.664 79.176 100.809C79.1277 100.954 79.0417 101.083 78.9267 101.184L79.4364 102.826Z"
        fill="url(#paint6_linear_1_7646)"
      />
      <path
        d="M80.9041 97.2805C80.7663 97.2997 80.6263 97.2659 80.5125 97.1861C80.3987 97.1063 80.3195 96.9864 80.2911 96.8506L80.1913 96.3425C80.0488 95.6492 79.659 95.0309 79.094 94.6022C78.529 94.1736 77.8273 93.9638 77.1189 94.0118C76.7612 94.0316 76.4119 94.128 76.0949 94.2944C75.7779 94.4608 75.5005 94.6934 75.2817 94.9762C75.0629 95.2591 74.9078 95.5856 74.8269 95.9336C74.746 96.2815 74.7412 96.6428 74.8129 96.9927L74.927 97.579C74.9397 97.6439 74.9388 97.7107 74.9244 97.7753C74.91 97.8398 74.8823 97.9007 74.8432 97.9541C74.804 98.0075 74.7542 98.0522 74.6968 98.0855C74.6395 98.1188 74.5759 98.1399 74.51 98.1474C74.3722 98.1666 74.2322 98.1329 74.1184 98.053C74.0046 97.9732 73.9254 97.8533 73.8969 97.7175L73.8007 97.2201C73.7029 96.7588 73.6996 96.2825 73.7913 95.8198C73.8829 95.3572 74.0674 94.9179 74.3338 94.5282C74.6001 94.1385 74.9428 93.8066 75.3412 93.5524C75.7396 93.2981 76.1855 93.1269 76.652 93.0489C77.6634 92.8794 78.7014 93.1044 79.5508 93.6773C80.4001 94.2502 80.9958 95.1269 81.2142 96.1258L81.3282 96.712C81.3415 96.7775 81.3408 96.8451 81.3262 96.9103C81.3116 96.9755 81.2833 97.0369 81.2433 97.0906C81.2033 97.1442 81.1524 97.1888 81.094 97.2215C81.0355 97.2543 80.9708 97.2744 80.9041 97.2805Z"
        fill="url(#paint7_linear_1_7646)"
      />
      <path
        d="M88.8021 112.034H67.7878L67.6453 107.87H88.6596L88.8021 112.034Z"
        fill="url(#paint8_linear_1_7646)"
      />
      <path
        d="M78.6522 88.448C78.6585 88.5999 78.6338 88.7514 78.5796 88.8935C78.5253 89.0356 78.4427 89.1652 78.3367 89.2745C78.2308 89.3838 78.1036 89.4705 77.963 89.5294C77.8224 89.5882 77.6713 89.618 77.5188 89.6169C77.2047 89.6153 76.9032 89.4928 76.6774 89.275C76.4517 89.0573 76.319 88.761 76.307 88.448C76.3002 88.296 76.3246 88.1442 76.3786 88.0019C76.4327 87.8596 76.5153 87.7298 76.6214 87.6204C76.7274 87.511 76.8548 87.4243 76.9956 87.3656C77.1364 87.3069 77.2878 87.2775 77.4404 87.2791C77.7543 87.2815 78.0553 87.4043 78.2809 87.6219C78.5065 87.8395 78.6394 88.1353 78.6522 88.448Z"
        fill="black"
      />
      <path
        d="M73.6125 88.448C73.6188 88.5998 73.594 88.7514 73.5398 88.8935C73.4856 89.0356 73.403 89.1652 73.297 89.2745C73.191 89.3838 73.0639 89.4705 72.9233 89.5293C72.7827 89.5882 72.6316 89.618 72.4791 89.6169C72.1646 89.6153 71.8627 89.493 71.6364 89.2753C71.41 89.0575 71.2765 88.7612 71.2637 88.448C71.2574 88.2961 71.2822 88.1445 71.3364 88.0024C71.3906 87.8604 71.4732 87.7307 71.5792 87.6214C71.6852 87.5121 71.8123 87.4254 71.9529 87.3666C72.0935 87.3077 72.2446 87.2779 72.3971 87.279C72.7116 87.2806 73.0135 87.4029 73.2398 87.6207C73.4662 87.8384 73.5997 88.1347 73.6125 88.448Z"
        fill="black"
      />
      <path
        d="M68.5577 88.448C68.5645 88.6 68.5401 88.7518 68.486 88.8941C68.432 89.0364 68.3494 89.1662 68.2433 89.2756C68.1372 89.385 68.0099 89.4717 67.8691 89.5304C67.7282 89.589 67.5769 89.6185 67.4243 89.6169C67.1103 89.6144 66.8094 89.4916 66.5838 89.274C66.3582 89.0564 66.2252 88.7606 66.2124 88.448C66.2062 88.2961 66.2309 88.1445 66.2851 88.0024C66.3394 87.8604 66.422 87.7307 66.5279 87.6214C66.6339 87.5121 66.7611 87.4254 66.9017 87.3666C67.0422 87.3077 67.1934 87.2779 67.3458 87.279C67.66 87.2806 67.9615 87.4031 68.1872 87.6209C68.413 87.8387 68.5457 88.135 68.5577 88.448Z"
        fill="black"
      />
      <path
        d="M83.7126 88.448C83.7194 88.6006 83.6948 88.753 83.6403 88.8957C83.5858 89.0385 83.5026 89.1687 83.3958 89.2782C83.2889 89.3877 83.1607 89.4742 83.019 89.5325C82.8774 89.5907 82.7252 89.6195 82.572 89.6169C82.2581 89.6144 81.9572 89.4916 81.7316 89.274C81.506 89.0564 81.373 88.7606 81.3602 88.448C81.3539 88.2961 81.3787 88.1445 81.4329 88.0024C81.4871 87.8604 81.5697 87.7307 81.6757 87.6214C81.7817 87.5121 81.9088 87.4254 82.0494 87.3666C82.19 87.3077 82.3411 87.2779 82.4936 87.279C82.809 87.2788 83.1122 87.4005 83.3396 87.6184C83.5669 87.8364 83.7006 88.1338 83.7126 88.448Z"
        fill="black"
      />
      <path
        d="M88.7673 88.448C88.7736 88.5998 88.7489 88.7514 88.6947 88.8935C88.6404 89.0356 88.5578 89.1652 88.4518 89.2745C88.3459 89.3838 88.2187 89.4705 88.0781 89.5293C87.9375 89.5882 87.7864 89.618 87.6339 89.6169C87.3194 89.6153 87.0176 89.493 86.7912 89.2753C86.5649 89.0575 86.4314 88.7612 86.4186 88.448C86.4123 88.2961 86.437 88.1445 86.4912 88.0024C86.5455 87.8604 86.6281 87.7307 86.7341 87.6214C86.84 87.5121 86.9672 87.4254 87.1078 87.3666C87.2484 87.3077 87.3995 87.2779 87.552 87.279C87.8665 87.2806 88.1683 87.4029 88.3947 87.6207C88.621 87.8384 88.7545 88.1347 88.7673 88.448Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_7646"
          x1="76"
          y1="117.729"
          x2="76"
          y2="-68.2709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.28" />
          <stop offset="1" stop-color="#FF1C3D" stop-opacity="0.08" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_7646"
          x1="101.38"
          y1="123.549"
          x2="34.9402"
          y2="110.876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_7646"
          x1="103.908"
          y1="121.029"
          x2="37.4674"
          y2="108.356"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_7646"
          x1="96.7658"
          y1="63.3289"
          x2="66.6514"
          y2="80.6545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1_7646"
          x1="87.1447"
          y1="74.8711"
          x2="68.3665"
          y2="62.715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1_7646"
          x1="83.8937"
          y1="59.2159"
          x2="72.8203"
          y2="65.5867"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1_7646"
          x1="83.3954"
          y1="104.956"
          x2="72.2263"
          y2="100.589"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_1_7646"
          x1="81.3377"
          y1="98.1527"
          x2="73.0371"
          y2="94.6012"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_1_7646"
          x1="88.8021"
          y1="112.034"
          x2="80.1932"
          y2="99.3524"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function AddedAccount() {
  return (
    <svg
      width="131"
      height="134"
      viewBox="0 0 131 134"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.958 86.074H6.04263C2.89305 86.074 0.339966 83.5209 0.339966 80.3714V6.22138C0.339966 3.0718 2.89305 0.518723 6.04263 0.518723H124.957C128.107 0.518723 130.66 3.0718 130.66 6.22138V80.3714C130.661 83.5204 128.108 86.074 124.958 86.074Z"
        fill="url(#paint0_linear_1_8206)"
      />
      <path
        d="M3.76242 40.4502C3.13285 40.4502 2.62189 39.9393 2.62189 39.3097V13.0774C2.62189 12.4479 3.13285 11.9369 3.76242 11.9369C4.39199 11.9369 4.90295 12.4479 4.90295 13.0774V39.3097C4.90295 39.9393 4.39256 40.4502 3.76242 40.4502ZM3.76242 9.65584C3.13285 9.65584 2.62189 9.14488 2.62189 8.51531V8.0494C2.62189 7.41983 3.13285 6.90887 3.76242 6.90887C4.39199 6.90887 4.90295 7.41983 4.90295 8.0494V8.51531C4.90295 9.14488 4.39256 9.65584 3.76242 9.65584Z"
        fill="white"
      />
      <path
        d="M124.958 0.518723V74.6687C124.958 77.8183 122.405 80.3714 119.255 80.3714H0.339966C0.339966 83.5209 2.89305 86.074 6.04263 86.074H124.957C128.107 86.074 130.66 83.5209 130.66 80.3714V6.22138C130.661 3.0718 128.107 0.518723 124.958 0.518723Z"
        fill="#FF4141"
      />
      <path
        d="M127.301 1.03013C127.624 1.74581 127.809 2.53734 127.809 3.37392V77.5239C127.809 80.6735 125.256 83.2266 122.106 83.2266H3.1907C2.35469 83.2266 1.56259 83.0424 0.847473 82.719C1.74165 84.6984 3.72903 86.0779 6.04203 86.0779H124.957C128.106 86.0779 130.659 83.5248 130.659 80.3752V6.22525C130.66 3.91168 129.28 1.92488 127.301 1.03013Z"
        fill="url(#paint1_linear_1_8206)"
      />
      <path
        d="M118.321 34.614H74.0304C73.4009 34.614 72.8899 34.1031 72.8899 33.4735C72.8899 32.8439 73.4009 32.333 74.0304 32.333H118.321C118.95 32.333 119.461 32.8439 119.461 33.4735C119.461 34.1031 118.95 34.614 118.321 34.614Z"
        fill="white"
      />
      <path
        d="M118.321 23.2111H74.0304C73.4009 23.2111 72.8899 22.7002 72.8899 22.0706C72.8899 21.441 73.4009 20.9301 74.0304 20.9301H118.321C118.95 20.9301 119.461 21.441 119.461 22.0706C119.461 22.7002 118.95 23.2111 118.321 23.2111Z"
        fill="white"
      />
      <path
        d="M118.321 46.017H74.0304C73.4009 46.017 72.8899 45.506 72.8899 44.8764C72.8899 44.2469 73.4009 43.7359 74.0304 43.7359H118.321C118.95 43.7359 119.461 44.2469 119.461 44.8764C119.461 45.506 118.95 46.017 118.321 46.017Z"
        fill="white"
      />
      <path
        d="M118.321 57.4238H74.0304C73.4009 57.4238 72.8899 56.9129 72.8899 56.2833C72.8899 55.6537 73.4009 55.1427 74.0304 55.1427H118.321C118.95 55.1427 119.461 55.6537 119.461 56.2833C119.461 56.9129 118.95 57.4238 118.321 57.4238Z"
        fill="white"
      />
      <path
        d="M63.9921 57.9937H12.6682V53.9984C12.6682 52.4239 13.945 51.1471 15.5195 51.1471H61.1408C62.7153 51.1471 63.9921 52.4239 63.9921 53.9984V57.9937Z"
        fill="#F1F2F2"
      />
      <path
        d="M62.28 27.8999H14.393C13.4441 27.8999 12.6748 27.1306 12.6748 26.1817C12.6748 25.6017 12.9674 25.0611 13.4521 24.744L37.3958 9.07199C37.9672 8.6979 38.7063 8.6979 39.2783 9.07199L63.222 24.744C63.7073 25.0617 63.9993 25.6023 63.9993 26.1817C63.9982 27.1306 63.2289 27.8999 62.28 27.8999Z"
        fill="#F1F2F2"
      />
      <path
        d="M53.1318 24.8461H23.5384C22.8199 24.8461 22.2377 24.2639 22.2377 23.5453C22.2377 23.0994 22.4663 22.6842 22.8433 22.4459L37.64 13.0889C38.0643 12.8203 38.606 12.8203 39.0303 13.0889L53.827 22.4464C54.2039 22.6848 54.4326 23.1 54.4326 23.5459C54.432 24.2639 53.8498 24.8461 53.1318 24.8461Z"
        fill="#D1D3D4"
      />
      <path
        d="M18.9684 31.4033H25.5008V47.6524H18.9684V31.4033Z"
        fill="#F1F2F2"
      />
      <path
        d="M51.1606 31.4036H57.693V47.6528H51.1606V31.4036Z"
        fill="#F1F2F2"
      />
      <path
        d="M17.5682 30.2581V27.8995H26.9V30.2581C26.9 30.8883 26.3896 31.3987 25.7595 31.3987H18.7087C18.0786 31.3987 17.5682 30.8883 17.5682 30.2581Z"
        fill="#D1D3D4"
      />
      <path
        d="M49.7634 30.2581V27.8995H59.0953V30.2581C59.0953 30.8883 58.5849 31.3987 57.9547 31.3987H50.904C50.2744 31.3987 49.7634 30.8883 49.7634 30.2581Z"
        fill="#D1D3D4"
      />
      <path
        d="M17.5682 51.1432V48.7846C17.5682 48.1545 18.0786 47.6441 18.7087 47.6441H25.7595C26.3896 47.6441 26.9 48.1545 26.9 48.7846V51.1432H17.5682Z"
        fill="#D1D3D4"
      />
      <path
        d="M49.7634 51.1432V48.7846C49.7634 48.1545 50.2738 47.6441 50.904 47.6441H57.9547C58.5849 47.6441 59.0953 48.1545 59.0953 48.7846V51.1432H49.7634Z"
        fill="#D1D3D4"
      />
      <path
        d="M39.5381 37.7845H39.4748V34.0447H39.5381C40.5692 34.0447 41.4075 34.8836 41.4075 35.914C41.4075 36.5436 41.9184 37.0546 42.548 37.0546C43.1776 37.0546 43.6885 36.5436 43.6885 35.914C43.6885 33.625 41.8266 31.7636 39.5381 31.7636H39.4748V31.7003C39.4748 31.0708 38.9639 30.5598 38.3343 30.5598C37.7047 30.5598 37.1938 31.0708 37.1938 31.7003V31.7636H37.1299C34.8414 31.7636 32.9795 33.6256 32.9795 35.914C32.9795 38.2025 34.8414 40.065 37.1299 40.065H37.1938V45.0081H37.1299C36.0989 45.0081 35.2606 44.1692 35.2606 43.1387C35.2606 42.5092 34.7496 41.9982 34.12 41.9982C33.4905 41.9982 32.9795 42.5092 32.9795 43.1387C32.9795 45.4278 34.8414 47.2891 37.1299 47.2891H37.1938V47.3524C37.1938 47.982 37.7047 48.493 38.3343 48.493C38.9639 48.493 39.4748 47.982 39.4748 47.3524V47.2897H39.5381C41.8266 47.2897 43.6885 45.4278 43.6885 43.1393V41.9349C43.6891 39.6464 41.8272 37.7845 39.5381 37.7845ZM37.1299 37.7845C36.0989 37.7845 35.2606 36.9456 35.2606 35.9146C35.2606 34.8836 36.0994 34.0453 37.1299 34.0453H37.1938V37.7851L37.1299 37.7845ZM41.408 43.1393C41.408 44.1703 40.5692 45.0086 39.5387 45.0086H39.4754V40.0656H39.5387C40.5697 40.0656 41.408 40.9044 41.408 41.9349V43.1393Z"
        fill="white"
      />
      <path
        d="M65.4999 133.549C83.6095 133.549 98.2902 118.869 98.2902 100.759C98.2902 82.6495 83.6095 67.9688 65.4999 67.9688C47.3903 67.9688 32.7096 82.6495 32.7096 100.759C32.7096 118.869 47.3903 133.549 65.4999 133.549Z"
        fill="white"
      />
      <path
        d="M87.2146 76.1935C92.3311 81.9772 95.4419 89.576 95.4419 97.9053C95.4419 116.015 80.7609 130.696 62.6516 130.696C54.3223 130.696 46.7235 127.584 40.9398 122.468C46.9476 129.26 55.7223 133.547 65.5029 133.547C83.6123 133.547 98.2932 118.867 98.2932 100.757C98.2932 90.9765 94.0059 82.2019 87.2146 76.1935Z"
        fill="#E7E7E7"
      />
      <path
        d="M79.7011 121.11H51.3019C49.7274 121.11 48.4506 119.833 48.4506 118.258V107.869C48.4506 103.145 52.2805 99.3151 57.0046 99.3151H73.9985C78.7226 99.3151 82.5525 103.145 82.5525 107.869V118.258C82.5525 119.833 81.2756 121.11 79.7011 121.11Z"
        fill="#E63950"
      />
      <path
        d="M78.4449 100.569C79.2369 101.865 79.7011 103.384 79.7011 105.015V115.404C79.7011 116.979 78.4243 118.255 76.8498 118.255H48.4506C48.4506 119.83 49.7274 121.107 51.3019 121.107H79.7011C81.2756 121.107 82.5525 119.83 82.5525 118.255V107.866C82.5525 104.773 80.905 102.071 78.4449 100.569Z"
        fill="#D10028"
      />
      <path
        d="M65.5001 100.892C71.1541 100.892 75.7375 96.3081 75.7375 90.6541C75.7375 85.0001 71.1541 80.4167 65.5001 80.4167C59.8461 80.4167 55.2627 85.0001 55.2627 90.6541C55.2627 96.3081 59.8461 100.892 65.5001 100.892Z"
        fill="#FFD2C0"
      />
      <path
        d="M71.1713 82.1289C72.2536 83.7525 72.8872 85.7017 72.8872 87.7997C72.8872 93.4539 68.3034 98.0371 62.6498 98.0371C60.5518 98.0371 58.6032 97.4035 56.9796 96.3212C58.8147 99.0733 61.9449 100.888 65.5011 100.888C71.1553 100.888 75.7385 96.3046 75.7385 90.651C75.7385 87.0943 73.9239 83.9641 71.1713 82.1289Z"
        fill="#FCC4AE"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_8206"
          x1="130.66"
          y1="86.074"
          x2="-10.0078"
          y2="23.9487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_8206"
          x1="130.659"
          y1="86.0779"
          x2="-9.36635"
          y2="24.1097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Users() {
  return (
    <svg
      width="36"
      height="20"
      viewBox="0 0 36 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.0376 9.75347L2.44104 9.98506V12.5267L0.602636 12.9828L4.86646 15.4519L4.83567 16.9359L4.77014 17.1631L7.29188 16.6059V16.0115C7.29188 14.9327 7.73085 13.9448 8.44972 13.2148L5.67572 12.5267L5.67257 9.98702L6.0768 9.75504C7.32504 9.03755 8.10035 7.70679 8.10035 6.28238V4.87953C8.10035 2.62124 6.34287 0.731303 4.18234 0.667038C3.08766 0.624717 2.03444 1.03539 1.24294 1.79716C0.451442 2.55933 0.015625 3.58286 0.015625 4.67733V6.28238C0.015625 7.70679 0.790148 9.03755 2.0376 9.75347Z"
        fill="#FF7E8D"
      />
      <path
        d="M10.7445 13.6752C9.66368 13.944 8.90771 14.9049 8.90771 16.0115V16.2489L9.37117 16.1471C11.0785 15.7717 12.8838 15.8508 14.5516 16.3814L18.2115 17.5425C18.6165 17.5577 19.0199 17.602 19.4108 17.7129L24.382 19.1228H24.8774L25.0772 19.0186V16.0115C25.0772 14.9049 24.3212 13.944 23.2403 13.6752L18.6102 12.5267L18.607 9.98702L19.0113 9.75504C20.2595 9.03755 21.0348 7.70679 21.0348 6.28238V4.87953C21.0348 2.62124 19.2773 0.731303 17.1168 0.667038C16.0257 0.624717 14.9689 1.03539 14.1774 1.79716C13.3859 2.55933 12.9501 3.58286 12.9501 4.67733V6.28238C12.9501 7.70679 13.7246 9.03755 14.972 9.75347L15.3755 9.98506V12.5267L10.7445 13.6752Z"
        fill="url(#paint0_linear_103_6287)"
      />
      <path
        d="M25.8867 4.67733V6.28238C25.8867 7.70679 26.6612 9.03755 27.9087 9.75347L28.3121 9.98506V12.5267L25.5374 13.2148C26.2554 13.9448 26.6952 14.9319 26.6952 16.0115V18.1734L35.586 13.5283L31.5468 12.5267L31.5437 9.98702L31.9479 9.75504C33.1961 9.03755 33.9714 7.70679 33.9714 6.28238V4.87953C33.9714 2.62124 32.214 0.731303 30.0534 0.667038C28.9564 0.624717 27.9063 1.03539 27.114 1.79716C26.3225 2.55933 25.8867 3.58286 25.8867 4.67733Z"
        fill="#FF7E8D"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_6287"
          x1="25.0772"
          y1="19.1228"
          x2="5.48345"
          y2="14.1464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Diamond() {
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.17338 2.00004L6.88837 5.43754L10.3979 2.00004H4.17338ZM12.6749 2.00004L16.1381 5.39172L18.7744 2H12.6748L12.6749 2.00004ZM11.5364 2.03638L7.73066 5.76353H15.342L11.5364 2.03638ZM19.5283 2.37716L16.8947 5.76349H22.2151L19.5283 2.37716ZM3.44336 2.40948L0.847773 5.76349H6.09375L3.44336 2.40948ZM0.81543 6.56897L10.1775 16.4141L6.43915 6.56888H0.815475L0.81543 6.56897ZM7.33193 6.56897L11.5363 17.6398L15.7407 6.56897H7.33202H7.33193ZM16.6335 6.56897L12.8953 16.4141L22.2573 6.56888H16.6335L16.6335 6.56897Z"
        fill="url(#paint0_linear_103_6300)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_6300"
          x1="22.2573"
          y1="17.6398"
          x2="-1.62746"
          y2="8.1456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}
export function DiamondLarge() {
  return (
    <svg
      width="32"
      height="27"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.17338 2.00004L6.88837 5.43754L10.3979 2.00004H4.17338ZM12.6749 2.00004L16.1381 5.39172L18.7744 2H12.6748L12.6749 2.00004ZM11.5364 2.03638L7.73066 5.76353H15.342L11.5364 2.03638ZM19.5283 2.37716L16.8947 5.76349H22.2151L19.5283 2.37716ZM3.44336 2.40948L0.847773 5.76349H6.09375L3.44336 2.40948ZM0.81543 6.56897L10.1775 16.4141L6.43915 6.56888H0.815475L0.81543 6.56897ZM7.33193 6.56897L11.5363 17.6398L15.7407 6.56897H7.33202H7.33193ZM16.6335 6.56897L12.8953 16.4141L22.2573 6.56888H16.6335L16.6335 6.56897Z"
        fill="url(#paint0_linear_103_6300)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_6300"
          x1="22.2573"
          y1="17.6398"
          x2="-1.62746"
          y2="8.1456"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function RedSeprator() {
  return (
    <svg
      width="155"
      height="11"
      viewBox="0 0 155 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M72.198 5.03027C74.9766 5.03027 77.2283 2.77859 77.2283 0C77.2283 2.77859 79.48 5.03027 82.2585 5.03027C79.48 5.03027 77.2283 7.28196 77.2283 10.0605C77.2283 7.28196 74.9766 5.03027 72.198 5.03027Z"
        fill="url(#paint0_linear_103_6303)"
      />
      <path
        d="M66.5176 5.03042C67.9048 5.03042 69.0327 3.90251 69.0327 2.51527C69.0327 3.90251 70.1606 5.03042 71.5479 5.03042C70.1606 5.03042 69.0327 6.15831 69.0327 7.54555C69.0327 6.15831 67.9089 5.03042 66.5176 5.03042Z"
        fill="url(#paint1_linear_103_6303)"
      />
      <path
        d="M82.9089 5.03042C84.2962 5.03042 85.4241 3.90251 85.4241 2.51527C85.4241 3.90251 86.552 5.03042 87.9392 5.03042C86.552 5.03042 85.4241 6.15831 85.4241 7.54555C85.4241 6.15831 84.2962 5.03042 82.9089 5.03042Z"
        fill="url(#paint2_linear_103_6303)"
      />
      <path
        d="M1.73291 5.03046H63.9156"
        stroke="url(#paint3_linear_103_6303)"
        stroke-width="1.5384"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.73302 6.76348C2.69013 6.76348 3.46603 5.98758 3.46603 5.03047C3.46603 4.07335 2.69013 3.29745 1.73302 3.29745C0.775897 3.29745 0 4.07335 0 5.03047C0 5.98758 0.775897 6.76348 1.73302 6.76348Z"
        fill="url(#paint4_linear_103_6303)"
      />
      <path
        d="M152.724 5.03046H90.5408"
        stroke="url(#paint5_linear_103_6303)"
        stroke-width="1.5384"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M152.723 6.76348C153.681 6.76348 154.457 5.98758 154.457 5.03047C154.457 4.07335 153.681 3.29745 152.723 3.29745C151.766 3.29745 150.99 4.07335 150.99 5.03047C150.99 5.98758 151.766 6.76348 152.723 6.76348Z"
        fill="url(#paint6_linear_103_6303)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_103_6303"
          x1="82.2585"
          y1="10.0605"
          x2="70.2874"
          y2="6.58962"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_103_6303"
          x1="71.5479"
          y1="7.54555"
          x2="65.5623"
          y2="5.81009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_103_6303"
          x1="87.9392"
          y1="7.54555"
          x2="81.9536"
          y2="5.81009"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_103_6303"
          x1="63.9156"
          y1="6.03046"
          x2="63.6696"
          y2="1.59512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_103_6303"
          x1="3.46603"
          y1="6.76348"
          x2="-0.658233"
          y2="5.56768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_103_6303"
          x1="152.724"
          y1="6.03046"
          x2="152.478"
          y2="1.59512"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_103_6303"
          x1="154.457"
          y1="6.76348"
          x2="150.332"
          y2="5.56769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function RedCapsule() {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1V8H0V1Z"
        fill="#FF566F"
      />
      <path
        d="M4 15C4 15.5523 3.55228 16 3 16L0.999999 16C0.447715 16 -4.37697e-07 15.5523 -3.89414e-07 15L2.22545e-07 8L4 8L4 15Z"
        fill="#DC1A36"
      />
    </svg>
  );
}

export function DiamondYellow() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_103_6324)">
        <path
          d="M4.29594 3.70363L6.6568 6.81886L9.70855 3.70363H4.29594ZM11.6885 3.70363L14.7 6.77734L16.9925 3.70359H11.6885L11.6885 3.70363ZM10.6985 3.73656L7.38922 7.11429H14.0078L10.6985 3.73656ZM17.648 4.04539L15.3579 7.11425H19.9844L17.648 4.04539ZM3.66113 4.07468L1.4041 7.11425H5.96582L3.66113 4.07468ZM1.37598 7.84421L9.51688 16.7664L6.26617 7.84414H1.37602L1.37598 7.84421ZM7.0425 7.84421L10.6985 17.8772L14.3545 7.84421H7.04258H7.0425ZM15.1308 7.84421L11.8802 16.7664L20.0211 7.84414H15.1309L15.1308 7.84421Z"
          fill="#FFB800"
        />
      </g>
      <defs>
        <clipPath id="clip0_103_6324">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.666748)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
export function RedStars() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 12.558L13.3762 15.5L12.0863 9.95055L16.3906 6.2191L10.7161 5.7318L8.5 0.5L6.28348 5.7318L0.609375 6.2191L4.91375 9.95055L3.62348 15.5L8.5 12.558Z"
        fill="url(#paint0_linear_107_569)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_107_569"
          x1="16.3906"
          y1="15.5"
          x2="-2.23329"
          y2="9.81893"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Recycle() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66675 1.66667L12.1667 4.16667M12.1667 4.16667L9.66675 6.66667M12.1667 4.16667H6.16675C4.76662 4.16667 4.06655 4.16667 3.53177 4.43916C3.06137 4.67884 2.67892 5.06129 2.43923 5.5317C2.16675 6.06648 2.16675 6.76654 2.16675 8.16667V12.9167C2.16675 13.3037 2.16675 13.4972 2.18814 13.6597C2.33583 14.7815 3.2186 15.6643 4.34043 15.812C4.50289 15.8333 4.6964 15.8333 5.08341 15.8333M8.83342 15.8333H14.8334C16.2335 15.8333 16.9336 15.8333 17.4684 15.5609C17.9388 15.3212 18.3212 14.9387 18.5609 14.4683C18.8334 13.9335 18.8334 13.2335 18.8334 11.8333V7.08334C18.8334 6.69632 18.8334 6.50281 18.812 6.34036C18.6643 5.21852 17.7816 4.33575 16.6597 4.18806C16.4973 4.16667 16.3038 4.16667 15.9167 4.16667M8.83342 15.8333L11.3334 18.3333M8.83342 15.8333L11.3334 13.3333"
        stroke="url(#paint0_linear_107_571)"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_107_571"
          x1="18.8334"
          y1="18.3333"
          x2="-0.998422"
          y2="12.5833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Premium() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.93148 5.79288C3.93148 5.40385 4.08603 5.03074 4.36112 4.75565C4.63621 4.48056 5.00932 4.32601 5.39835 4.32601H6.06511C6.45243 4.32579 6.82395 4.17239 7.09859 3.89929L7.56532 3.43256C7.70164 3.29547 7.86371 3.18668 8.04222 3.11245C8.22072 3.03822 8.41214 3 8.60547 3C8.79879 3 8.99021 3.03822 9.16872 3.11245C9.34722 3.18668 9.5093 3.29547 9.64561 3.43256L10.1123 3.89929C10.3871 4.17266 10.7591 4.32601 11.1458 4.32601H11.8126C12.2016 4.32601 12.5747 4.48056 12.8498 4.75565C13.1249 5.03074 13.2795 5.40385 13.2795 5.79288V6.45964C13.2795 6.84637 13.4328 7.21842 13.7062 7.49312L14.1729 7.95985C14.31 8.09617 14.4188 8.25824 14.493 8.43675C14.5673 8.61525 14.6055 8.80667 14.6055 9C14.6055 9.19333 14.5673 9.38474 14.493 9.56325C14.4188 9.74176 14.31 9.90383 14.1729 10.0401L13.7062 10.5069C13.4331 10.7815 13.2797 11.153 13.2795 11.5404V12.2071C13.2795 12.5962 13.1249 12.9693 12.8498 13.2443C12.5747 13.5194 12.2016 13.674 11.8126 13.674H11.1458C10.7585 13.6742 10.387 13.8276 10.1123 14.1007L9.64561 14.5674C9.5093 14.7045 9.34722 14.8133 9.16872 14.8876C8.99021 14.9618 8.79879 15 8.60547 15C8.41214 15 8.22072 14.9618 8.04222 14.8876C7.86371 14.8133 7.70164 14.7045 7.56532 14.5674L7.09859 14.1007C6.82395 13.8276 6.45243 13.6742 6.06511 13.674H5.39835C5.00932 13.674 4.63621 13.5194 4.36112 13.2443C4.08603 12.9693 3.93148 12.5962 3.93148 12.2071V11.5404C3.93126 11.153 3.77786 10.7815 3.50476 10.5069L3.03802 10.0401C2.90094 9.90383 2.79215 9.74176 2.71792 9.56325C2.64368 9.38474 2.60547 9.19333 2.60547 9C2.60547 8.80667 2.64368 8.61525 2.71792 8.43675C2.79215 8.25824 2.90094 8.09617 3.03802 7.95985L3.50476 7.49312C3.77786 7.21848 3.93126 6.84696 3.93148 6.45964V5.79288Z"
        fill="#0EA5E9"
      />
      <path
        d="M6.60547 9.5L7.9388 11L10.6055 8"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function CopyDoc() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.293 8.21875H8.93672C7.82422 8.21875 6.91797 7.31875 6.91797 6.2V4.84375C6.91797 4.5875 6.71172 4.375 6.44922 4.375H4.46797C3.02422 4.375 1.85547 5.3125 1.85547 6.9875V11.1375C1.85547 12.8125 3.02422 13.75 4.46797 13.75H8.14922C9.59297 13.75 10.7617 12.8125 10.7617 11.1375V8.6875C10.7617 8.425 10.5492 8.21875 10.293 8.21875Z"
        fill="black"
      />
      <path
        d="M11.7429 1.25H10.5117H9.83044H8.06169C6.64919 1.25 5.50544 2.15 5.45544 3.75625C5.49294 3.75625 5.52419 3.75 5.56169 3.75H7.33044H8.01169H9.24294C10.6867 3.75 11.8554 4.6875 11.8554 6.3625V7.59375V9.2875V10.5188C11.8554 10.5562 11.8492 10.5875 11.8492 10.6187C13.2429 10.575 14.3554 9.65 14.3554 8.01875V6.7875V5.09375V3.8625C14.3554 2.1875 13.1867 1.25 11.7429 1.25Z"
        fill="black"
      />
      <path
        d="M8.09299 4.46872C7.89924 4.27497 7.56799 4.40622 7.56799 4.67497V6.31247C7.56799 6.99997 8.14924 7.56247 8.86174 7.56247C9.30549 7.56872 9.92424 7.56872 10.4555 7.56872C10.7242 7.56872 10.8617 7.25622 10.6742 7.06872C9.99299 6.38747 8.78049 5.16872 8.09299 4.46872Z"
        fill="black"
      />
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6055 14L12.2722 11.6667M13.9388 7.66667C13.9388 10.7963 11.4017 13.3333 8.27214 13.3333C5.14252 13.3333 2.60547 10.7963 2.60547 7.66667C2.60547 4.53705 5.14252 2 8.27214 2C11.4017 2 13.9388 4.53705 13.9388 7.66667Z"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function CoinIcon() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_240_903)">
        <path
          d="M7.33952 2.66659C8.18121 1.84179 9.33397 1.33325 10.6055 1.33325C13.1828 1.33325 15.2722 3.42259 15.2722 5.99992C15.2722 7.27148 14.7636 8.42425 13.9388 9.26595M5.60551 8.66659L6.60551 7.99992V11.6666M5.60551 11.6666H7.60551M11.2722 9.99992C11.2722 12.5772 9.18284 14.6666 6.60551 14.6666C4.02818 14.6666 1.93884 12.5772 1.93884 9.99992C1.93884 7.42259 4.02818 5.33325 6.60551 5.33325C9.18284 5.33325 11.2722 7.42259 11.2722 9.99992Z"
          stroke="#344054"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_240_903">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.605469)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SettingIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6055 22V11"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.6055 7V2"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6055 22V17"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.6055 13V2"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.60547 22V11"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.60547 7V2"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.60547 11H7.60547"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.6055 11H21.6055"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6055 13H14.6055"
        stroke="#101828"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function OrangeCoinIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 45 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.8046 32.0893C39.0349 24.3594 39.3118 14.2455 33.423 9.4991C27.5342 4.75273 17.7098 7.1713 11.4795 14.9011C5.24925 22.631 4.97239 32.7449 10.8611 37.4913C16.7499 42.2377 26.5743 39.8191 32.8046 32.0893Z"
        fill="#FFA903"
      />
      <path
        d="M33.283 9.50704C30.945 7.68583 27.9977 6.83045 25.048 7.11704C27.3233 7.25687 29.501 8.09103 31.2874 9.50704C37.1757 14.2536 36.8996 24.3671 30.6695 32.0968C26.8678 36.8137 21.7287 39.5479 16.9611 39.8885C22.2426 40.2841 28.3247 37.4805 32.6645 32.0968C38.8945 24.3671 39.1719 14.253 33.283 9.50704Z"
        fill="white"
      />
      <path
        d="M35.2717 10.1541C34.9617 9.90382 34.6398 9.66867 34.3071 9.44946C34.2849 9.43883 34.2622 9.42952 34.2389 9.42159C34.1323 9.36885 34.0131 9.34697 33.8947 9.35844C33.7731 9.37069 33.6587 9.4217 33.5684 9.50394C33.5482 9.51704 33.5287 9.53131 33.5102 9.54667C33.4984 9.55905 33.4947 9.57577 33.4842 9.58877C33.4737 9.60177 33.4588 9.60982 33.4489 9.62406C33.4385 9.64621 33.4294 9.66895 33.4216 9.69217C33.3682 9.79989 33.3463 9.92052 33.3585 10.0401C33.3638 10.0787 33.3727 10.1166 33.3851 10.1535C33.4068 10.2314 33.4471 10.3028 33.5028 10.3615C33.516 10.3824 33.5305 10.4024 33.5461 10.4216C38.6716 15.1495 38.1372 24.499 32.3294 31.7055C26.3222 39.1572 16.8687 41.538 11.2566 37.0137C11.2202 36.9896 11.1814 36.9695 11.1408 36.9536L11.1067 36.9357C11.0476 36.9109 10.9849 36.8954 10.921 36.8898L10.8715 36.8855C10.7393 36.8823 10.6101 36.9252 10.5062 37.0069C10.4853 37.0177 10.465 37.0297 10.4455 37.0428C10.4331 37.0533 10.4288 37.0682 10.4182 37.0799C10.4083 37.0905 10.3947 37.0954 10.3854 37.1066C10.3748 37.1248 10.365 37.1436 10.3563 37.1629C10.3263 37.2063 10.302 37.2533 10.2839 37.3028C10.2779 37.3226 10.273 37.3426 10.269 37.3629C10.2545 37.423 10.2491 37.485 10.2529 37.5468L10.2585 37.5827C10.2676 37.6499 10.2877 37.7152 10.3179 37.7759L10.3346 37.8037C10.353 37.8438 10.3757 37.8818 10.4021 37.917C10.8381 38.3989 11.3114 38.8457 11.8176 39.2532C14.1835 41.1247 17.1235 42.121 20.1398 42.0735C25.4585 42.0735 31.2168 39.2997 35.3268 34.2008C41.7934 26.1782 41.7687 15.3904 35.2717 10.1541Z"
        fill="#F1B31C"
      />
      <path
        d="M13.7866 29.7372C13.8318 29.5936 14.1643 28.3664 14.2083 28.2233C17.8725 28.2884 18.0533 28.1664 18.5976 26.3603C19.6254 22.9499 19.9251 22.3394 20.9535 18.929C21.3145 17.7327 21.2507 17.4715 19.5356 17.1445L18.4911 16.952C18.5301 16.8257 18.7641 15.6672 18.8031 15.5415C21.3319 15.5431 23.8581 15.3799 26.3657 15.053L22.9609 26.8172C22.4296 28.6537 22.4952 28.801 26.0134 29.4809C25.9725 29.6258 25.6419 30.8542 25.6016 30.9997L13.7866 29.7372Z"
        fill="white"
      />
      <path
        d="M33.9544 9.9585C34.2681 10.1653 34.574 10.3886 34.872 10.6284C41.1133 15.6586 41.0959 26.0353 34.8343 33.804C28.5726 41.5727 18.4362 43.7943 12.1949 38.7642C11.714 38.377 11.2644 37.9526 10.8501 37.4949"
        stroke="#1D2939"
        stroke-width="0.928755"
        stroke-miterlimit="10"
      />
      <path
        d="M32.7994 32.0885C39.0296 24.3587 39.3065 14.2447 33.4177 9.49837C27.529 4.75199 17.7046 7.17057 11.4743 14.9004C5.244 22.6302 4.96714 32.7442 10.8559 37.4905C16.7447 42.2369 26.5691 39.8183 32.7994 32.0885Z"
        stroke="#1D2939"
        stroke-width="0.928755"
        stroke-miterlimit="10"
      />
      <path
        d="M32.8015 32.085L34.8361 33.8032M30.661 34.4038L32.4542 36.3313M28.3955 36.2885L29.8679 38.3454M25.9102 37.9467L27.0692 40.0172M34.4504 29.7693L36.6107 31.264"
        stroke="#1D2939"
        stroke-width="0.928755"
        stroke-miterlimit="10"
      />
      <path
        d="M13.777 29.7323C13.8222 29.5887 14.1547 28.3615 14.1986 28.2185C17.8629 28.2835 18.0437 28.1615 18.5879 26.3554C19.6158 22.945 19.9154 22.3345 20.9439 18.9241C21.3048 17.7279 21.2411 17.4666 19.526 17.1397L18.4814 16.9471C18.5204 16.8208 18.7545 15.6623 18.7935 15.5366C21.3223 15.5382 23.8485 15.3751 26.356 15.0481L22.9512 26.8123C22.42 28.6488 22.4856 28.7962 26.0037 29.476C25.9629 29.6209 25.6322 30.8493 25.592 30.9948L13.777 29.7323Z"
        stroke="#1D2939"
        stroke-width="0.928755"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export function WithDrawlSuccess() {
  return (
    <svg width="151" height="111" viewBox="0 0 151 111" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M84.1286 82.7935C125.826 82.7935 151 121.422 151 78.3355C151 35.249 117.197 0.320435 75.5 0.320435C33.8025 0.320435 0 35.249 0 78.3355C0 121.422 42.4311 82.7935 84.1286 82.7935Z" fill="url(#paint0_linear_1_7335)" />
      <path d="M96 63.499L68.5 91.9812L56 79.0347" stroke="url(#paint1_linear_1_7335)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_1_7335" x1="76" y1="121.404" x2="76" y2="-71.2384" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0.28" />
          <stop offset="1" stop-color="#FF1C3D" stop-opacity="0.08" />
        </linearGradient>
        <linearGradient id="paint1_linear_1_7335" x1="96" y1="91.9812" x2="51.7407" y2="73.9592" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export function DiamondSelling() {
  return (
    <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.25838 0.680743L10.9801 6.91121L17.0836 0.680743H6.25838ZM21.0435 0.680743L27.0665 6.82816L31.6515 0.680664H21.0435L21.0435 0.680743ZM19.0635 0.746602L12.4449 7.50207H25.6821L19.0635 0.746602ZM32.9625 1.36426L28.3824 7.50199H37.6353L32.9625 1.36426ZM4.98877 1.42285L0.474707 7.50199H9.59814L4.98877 1.42285ZM0.418457 8.96191L16.7003 26.8063L10.1988 8.96176H0.418535L0.418457 8.96191ZM11.7515 8.96191L19.0635 29.0278L26.3754 8.96191H11.7517H11.7515ZM27.9281 8.96191L21.427 26.8063L37.7086 8.96176H27.9282L27.9281 8.96191Z" fill="white" />
    </svg>
  )
}

export function UpIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z" fill="#FFEBED" />
      <path d="M20 28V12M20 12L14 18M20 12L26 18" stroke="url(#paint0_linear_1_6755)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <defs>
        <linearGradient id="paint0_linear_1_6755" x1="26" y1="28" x2="11.2196" y2="24.7859" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export function DownIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8C0 3.58172 3.58172 0 8 0H32C36.4183 0 40 3.58172 40 8V32C40 36.4183 36.4183 40 32 40H8C3.58172 40 0 36.4183 0 32V8Z" fill="#EEFFEB" />
      <path d="M20 12V28M20 28L26 22M20 28L14 22" stroke="#34C759" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export function Coin() {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_6763)">
        <path d="M10.5871 10.4197C12.935 7.50674 13.0393 3.69531 10.8201 1.90665C8.60095 0.117983 4.89863 1.02942 2.55076 3.94239C0.202887 6.85536 0.0985527 10.6668 2.31772 12.4555C4.53689 14.2441 8.23921 13.3327 10.5871 10.4197Z" fill="#FFA903" />
        <path d="M10.7659 1.90746C9.88483 1.22114 8.77415 0.898788 7.66256 1.00679C8.51999 1.05948 9.34064 1.37384 10.0139 1.90746C12.2329 3.69619 12.1288 7.50746 9.78099 10.4204C8.34832 12.1979 6.41166 13.2283 4.61499 13.3567C6.60532 13.5058 8.89736 12.4492 10.5328 10.4204C12.8806 7.50746 12.9851 3.69596 10.7659 1.90746Z" fill="white" />
        <path d="M11.5147 2.15251C11.3979 2.05821 11.2765 1.96959 11.1512 1.88698C11.1428 1.88298 11.1342 1.87947 11.1255 1.87648C11.0853 1.8566 11.0404 1.84836 10.9958 1.85268C10.9499 1.8573 10.9068 1.87652 10.8728 1.90751C10.8652 1.91245 10.8578 1.91783 10.8509 1.92361C10.8464 1.92828 10.845 1.93458 10.8411 1.93948C10.8371 1.94438 10.8315 1.94741 10.8278 1.95278C10.8238 1.96113 10.8204 1.9697 10.8175 1.97845C10.7974 2.01904 10.7891 2.0645 10.7937 2.10958C10.7957 2.1241 10.799 2.13839 10.8037 2.15228C10.8119 2.18164 10.8271 2.20856 10.8481 2.23068C10.853 2.23855 10.8585 2.2461 10.8644 2.25331C12.7959 4.03505 12.5946 7.55838 10.4059 10.2741C8.14209 13.0823 4.57956 13.9795 2.46462 12.2745C2.45092 12.2655 2.43629 12.2579 2.42099 12.2519L2.40816 12.2451C2.38586 12.2358 2.36224 12.2299 2.33816 12.2278L2.31949 12.2262C2.26968 12.225 2.22102 12.2412 2.18182 12.2719C2.17395 12.276 2.16632 12.2805 2.15896 12.2855C2.15429 12.2894 2.15266 12.295 2.14869 12.2995C2.14496 12.3034 2.13982 12.3053 2.13632 12.3095C2.13231 12.3164 2.12865 12.3235 2.12536 12.3307C2.11405 12.3471 2.10487 12.3648 2.09806 12.3835C2.09581 12.3909 2.09395 12.3985 2.09246 12.4061C2.08698 12.4288 2.08494 12.4521 2.08639 12.4754L2.08849 12.4889C2.09192 12.5143 2.09949 12.5389 2.11089 12.5617L2.11719 12.5722C2.12412 12.5873 2.13265 12.6017 2.14262 12.6149C2.30694 12.7965 2.48529 12.9649 2.67602 13.1185C3.56764 13.8237 4.67558 14.1992 5.81226 14.1813C7.81659 14.1813 9.98659 13.136 11.5355 11.2145C13.9724 8.19118 13.9631 4.12581 11.5147 2.15251Z" fill="#F1B31C" />
        <path d="M3.4187 9.53373C3.43573 9.4796 3.56103 9.01713 3.5776 8.96323C4.95847 8.98773 5.0266 8.94177 5.2317 8.26113C5.61903 6.97593 5.73197 6.74587 6.11953 5.46067C6.25557 5.00987 6.23153 4.9114 5.5852 4.7882L5.19157 4.71563C5.20627 4.66803 5.29447 4.23147 5.30917 4.1841C6.26214 4.18471 7.21413 4.12321 8.1591 4L6.876 8.43333C6.6758 9.1254 6.70053 9.18093 8.02633 9.43713C8.01093 9.49173 7.88633 9.95467 7.87117 10.0095L3.4187 9.53373Z" fill="white" />
        <path d="M11.0223 2.08006C11.1405 2.158 11.2558 2.24215 11.3681 2.33253C13.7201 4.22813 13.7135 8.13856 11.3538 11.0662C8.99413 13.9938 5.17423 14.831 2.82223 12.9354C2.64101 12.7895 2.47155 12.6296 2.31543 12.4571" stroke="#1D2939" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M10.5871 10.4197C12.935 7.50672 13.0393 3.69529 10.8201 1.90663C8.60095 0.117968 4.89863 1.0294 2.55076 3.94238C0.202887 6.85535 0.0985527 10.6668 2.31772 12.4554C4.53689 14.2441 8.23921 13.3327 10.5871 10.4197Z" stroke="#1D2939" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M10.5867 10.4195L11.3535 11.067M9.78011 11.2934L10.4558 12.0197M8.92635 12.0036L9.48121 12.7788M7.98975 12.6285L8.42655 13.4088M11.2081 9.54688L12.0222 10.1101" stroke="#1D2939" stroke-width="0.5" stroke-miterlimit="10" />
        <path d="M3.4187 9.53373C3.43573 9.4796 3.56103 9.01713 3.5776 8.96323C4.95847 8.98773 5.0266 8.94177 5.2317 8.26113C5.61903 6.97593 5.73197 6.74587 6.11953 5.46067C6.25557 5.00987 6.23153 4.9114 5.5852 4.7882L5.19157 4.71563C5.20627 4.66803 5.29447 4.23147 5.30917 4.1841C6.26214 4.18471 7.21413 4.12321 8.1591 4L6.876 8.43333C6.6758 9.1254 6.70053 9.18093 8.02633 9.43713C8.01093 9.49173 7.88633 9.95467 7.87117 10.0095L3.4187 9.53373Z" stroke="#1D2939" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1_6763">
          <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function SuccessHost() {
  return (
    <svg width="177" height="167" viewBox="0 0 177 167" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M98.5203 93.1953C146.945 93.1953 176.181 136.509 176.181 88.1966C176.181 39.8839 136.925 0.71875 88.4996 0.71875C40.0746 0.71875 0.818359 39.8839 0.818359 88.1966C0.818359 136.509 50.0953 93.1953 98.5203 93.1953Z" fill="url(#paint0_linear_93_2918)" />
      <g filter="url(#filter0_d_93_2918)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.8702 38.1577H111.729L128.856 55.1994V140.781C128.856 141.983 127.87 142.964 126.661 142.964H47.8702C46.6615 142.964 45.6758 141.983 45.6758 140.781V40.341C45.6758 39.1385 46.6615 38.1577 47.8702 38.1577Z" fill="url(#paint1_linear_93_2918)" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M53.3345 34.6641H117.193L134.32 51.7058V137.287C134.32 138.49 133.334 139.47 132.126 139.47H53.3345C52.1259 139.47 51.1401 138.49 51.1401 137.287V36.8473C51.1401 35.6449 52.1259 34.6641 53.3345 34.6641Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M119.387 51.7058H134.32L117.193 34.6641V49.5223C117.193 50.725 118.179 51.7058 119.387 51.7058Z" fill="url(#paint2_linear_93_2918)" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62.1123 46.6956H86.6146C87.8233 46.6956 88.809 47.6764 88.809 48.879V75.5815C88.809 76.7841 87.8233 77.7649 86.6146 77.7649H62.1123C60.9037 77.7649 59.918 76.7841 59.918 75.5815V48.879C59.918 47.6766 60.9037 46.6956 62.1123 46.6956Z" fill="url(#paint3_linear_93_2918)" />
        <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M69.7158 60.4691C69.7158 63.0235 71.7968 65.0939 74.3637 65.0939C76.9307 65.0939 79.0117 63.0233 79.0117 60.4691C79.0117 57.9155 76.9307 55.845 74.3637 55.845C71.7968 55.845 69.7158 57.9155 69.7158 60.4691Z" fill="white" />
        <path opacity="0.6" fill-rule="evenodd" clip-rule="evenodd" d="M84.4199 77.7652H64.3069C64.3069 72.2391 68.8098 67.759 74.3637 67.759C79.9175 67.759 84.4199 72.2391 84.4199 77.7652Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M59.918 128.967C59.0697 128.967 58.382 128.282 58.382 127.438C58.382 126.594 59.0697 125.91 59.918 125.91H92.7302C93.5785 125.91 94.2662 126.594 94.2662 127.438C94.2662 128.282 93.5785 128.967 92.7302 128.967H59.918ZM108.415 51.3997C109.264 51.3997 109.951 52.084 109.951 52.9281C109.951 53.7722 109.264 54.4565 108.415 54.4565H97.5868C96.7384 54.4565 96.0507 53.7722 96.0507 52.9281C96.0507 52.084 96.7384 51.3997 97.5868 51.3997H108.415ZM125.542 63.8181C126.391 63.8181 127.078 64.5024 127.078 65.3465C127.078 66.1907 126.391 66.875 125.542 66.875H97.5868C96.7384 66.875 96.0507 66.1907 96.0507 65.3465C96.0507 64.5024 96.7384 63.8181 97.5868 63.8181H125.542ZM125.542 76.2363C126.391 76.2363 127.078 76.9206 127.078 77.7648C127.078 78.6089 126.391 79.2932 125.542 79.2932H97.5868C96.7384 79.2932 96.0507 78.6089 96.0507 77.7648C96.0507 76.9206 96.7384 76.2363 97.5868 76.2363H125.542ZM59.918 91.7114C59.0697 91.7114 58.382 91.0271 58.382 90.183C58.382 89.3389 59.0697 88.6546 59.918 88.6546H125.542C126.39 88.6546 127.078 89.3389 127.078 90.183C127.078 91.0271 126.39 91.7114 125.542 91.7114H59.918ZM59.918 104.13C59.0697 104.13 58.382 103.445 58.382 102.601C58.382 101.757 59.0697 101.073 59.918 101.073H125.542C126.39 101.073 127.078 101.757 127.078 102.601C127.078 103.445 126.39 104.13 125.542 104.13H59.918ZM59.918 116.548C59.0697 116.548 58.382 115.864 58.382 115.02C58.382 114.176 59.0697 113.491 59.918 113.491H125.542C126.39 113.491 127.078 114.176 127.078 115.02C127.078 115.864 126.39 116.548 125.542 116.548H59.918Z" fill="#FF7E8D" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M130.27 117.096L133.965 113.42L141.097 120.516L137.402 124.193L130.27 117.096Z" fill="#636C77" />
        <path d="M133.676 116.805C140.978 109.54 140.978 97.7604 133.676 90.495C126.375 83.2296 114.536 83.2296 107.234 90.495C99.9327 97.7604 99.9327 109.54 107.234 116.805C114.536 124.071 126.375 124.071 133.676 116.805Z" fill="#A9B0BB" />
        <path d="M125.157 116.692C132.39 114.089 136.132 106.145 133.516 98.9485C130.9 91.7518 122.916 88.028 115.683 90.631C108.451 93.2341 104.708 101.178 107.324 108.375C109.94 115.571 117.924 119.295 125.157 116.692Z" fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M136.579 122.487L139.402 119.677C140.335 118.749 141.861 118.749 142.794 119.677L155.771 132.589C156.704 133.518 156.704 135.036 155.771 135.964L152.947 138.774C152.014 139.703 150.488 139.703 149.555 138.774L136.579 125.862C135.646 124.934 135.646 123.415 136.579 122.487Z" fill="url(#paint4_linear_93_2918)" />
      </g>
      <defs>
        <filter id="filter0_d_93_2918" x="31.6758" y="30.6641" width="138.795" height="136.3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="7" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_93_2918" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_93_2918" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_93_2918" x1="89.0803" y1="136.49" x2="89.0803" y2="-79.5198" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0.28" />
          <stop offset="1" stop-color="#FF1C3D" stop-opacity="0.08" />
        </linearGradient>
        <linearGradient id="paint1_linear_93_2918" x1="128.856" y1="142.964" x2="26.9544" y2="119.515" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient id="paint2_linear_93_2918" x1="134.32" y1="51.7058" x2="113.956" y2="45.772" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient id="paint3_linear_93_2918" x1="88.809" y1="77.7649" x2="54.0667" y2="68.398" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
        <linearGradient id="paint4_linear_93_2918" x1="156.471" y1="139.47" x2="131.987" y2="132.336" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF7081" />
          <stop offset="1" stop-color="#FF1B36" />
        </linearGradient>
      </defs>
    </svg>
  )
}