import React from 'react'
import { Recycle, RedStars } from '../utils/svg'

const StarText = ({ text, className }) => {
    return (
        <div className={`flex-custom-space-center mt-3 company-withdrawl-text ${className}`}>
            <RedStars />
            <span>{text}</span>
            <Recycle />
        </div>
    )
}

export default StarText