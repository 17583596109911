import React from "react";
import { Button } from "react-bootstrap";

const ChooseButton = ({ className, first, second, active, setActive }) => {
  return (
    <div className={`choose-btn flex-custom-space-center ${className}`}>
      <Button className={active === 0 && "active"} onClick={() => setActive(0)}>
        {first}
      </Button>
      <Button className={active === 1 && "active"} onClick={() => setActive(1)}>
        {second}
      </Button>
    </div>
  );
};

export default ChooseButton;
