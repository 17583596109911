import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LuArrowDownUp } from "react-icons/lu";
import { PiWarningCircleLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import profile from "../assets/images/profile.png";
import ChooseButton from "../components/ChooseButton";
import CurrentBalance from "../components/CurrentBalance";
import StarText from "../components/StarText";
import FooterSaveButton from "../components/Theme/FooterButton";
import ThemeOffcanvas from "../components/Theme/ThemeOffcanvas";
import TopWalletHeader from "../components/TopWalletHeader";
import WithdrawlBody from "../components/offcanvasBody/WithDrawlBody";
import { ROUTES } from "../utils/app-constants";
import { CoinYellow, Diamond, DiamondLarge, RedCapsule, RedSeprator } from "../utils/svg";
import ContentContainer from "../components/ContentContainer";
import SelectRadio from "../components/SelectRadio";
import ExchangeBody from "../components/offcanvasBody/ExchangeBody";

const ExchangeCustom = () => {
    const navigate = useNavigate()

    const [show, setShow] = useState(false)
    const [activeType] = useState(1)
    const [selectedToggle, setSelectedToggle] = useState(1)


    const setActiveType = () => {
        window?.ReactNativeWebView?.postMessage('coins');
    }

    const handleActiveTab = () => {
        navigate(ROUTES.exchange)
    }

    const handleToggle = () => {
        navigate(ROUTES.withdrawl)
    }

    return (
        <div className="withdrawl">
            <div className="container">
                <TopWalletHeader page={"Exchange"} className={""} />
            </div>
            <div className="container inner pb-3">
                <div className="flex-custom-space-center w-100 mb-3">
                    <ChooseButton className="mt-3 coins-choose-btn" first={"Coin"} second={"Diamond"} active={activeType} setActive={setActiveType} />
                </div>
                <CurrentBalance className="balance mt-2" text={
                    <div className="diamond-count flex-custom-space-start">
                        <Diamond />
                        12,457,567
                    </div>
                } heading={"Current Balance"} />
                <div className="flex-custom-space-center mt-3">
                    <RedSeprator />
                </div>
                <div className="select-toggle d-flex mt-3">
                    <RedCapsule />
                    <SelectRadio className="ms-2" selected={selectedToggle} setSelected={handleToggle} />
                </div>
                {/* <div className="heading mt-4 flex-custom-space-start">
                    <RedCapsule />
                    <h2>Withdrawal</h2>
                </div> */}
                <div className="flex-custom-space-center">
                    <ChooseButton className="mt-3" first={"Choose"} second={"Custom Amt"} active={1} setActive={handleActiveTab} />
                </div>

                <div className="p-2 enter-diamonds mt-4 d-flex align-items-center justify-content-center flex-column">
                    <h2>Enter Diamonds</h2>
                    <div className="d-flex flex-row align-items-center">
                        <span className="d-flex me-2">
                            <DiamondLarge />
                        </span>
                        <h1 className="fw-bold">40000</h1>
                    </div>
                    <LuArrowDownUp color="#FF4B62" size={23} className="my-2" />
                    <div className="d-flex flex-row align-items-center">
                        <span className="d-flex me-2"><CoinYellow /></span><span className="text-black fw-bold">100000</span>
                    </div>
                    <p className="text-black">Coins</p>
                </div>
                <ContentContainer
                    background="#FFF2F4"
                    borderRadius="8px"
                    classes="w-100 mt-5 mb-2 p-3"
                >
                    <div className="d-flex flex-row gap-3">
                        <PiWarningCircleLight size={24} color="#FF3B52" />
                        <div className="d-flex flex-column">
                            <p>Rules</p>
                            <p>----</p>
                            <p>--</p>
                            <br />
                            <br />
                            <p>---</p>
                            <p>--</p>
                            <p>---</p>
                            <p>----</p>
                            <br />
                            <br />
                            <br />

                        </div>
                    </div>
                </ContentContainer>
                {/* <StarText text={"Reseller/Regional Withdrawal"} /> */}
                {/* <div className="warn d-flex mt-3">
                    <div>
                        <PiWarningCircleLight size={24} color="#FF3B52" />
                    </div>
                    <p>Once your withdraw request is approved, you'll receive the fee within around 15 working days according to the payment method you chose.</p>
                </div>
                <div className="agree mt-4">
                    <Form>
                        <div key={`default`} className="mb-3">
                            <Form.Check
                                type={"checkbox"}
                                // id={`default-${type}`}
                                label={<div className={"agree-text"}>I agree to the <Link to="/dashboard">Terms & Conditions</Link> by clicking confirm.</div>}
                            />
                        </div>
                    </Form>
                </div> */}
            </div>
            <FooterSaveButton className="theme-lite-btn border-none-theme w-100" text={"Exchange"} onClick={() => setShow(true)} />
            <ThemeOffcanvas show={show} onHide={() => { setShow(!show) }} body={<ExchangeBody setShow={setShow} />} />
        </div>
    )
}

export default ExchangeCustom;