import React from "react";
import AppLayout from "../Layouts/AppLayout";
import SearchBar from "./../components/SearchBar";
import TeamCard from "./../components/TeamCard";

const Agency = () => {

    return (
        <>
            <AppLayout isFooter style={{ backgroundColor: "#efefef" }}>
                <div className="container pt-3 pb-5 agency">
                    <div className="py-3">
                        <div className="mb-3">
                            <SearchBar />
                        </div>

                        <div className="mb-3">
                            <TeamCard
                                bgColor="#fff"
                                verified
                                shadow
                                title='Gojo Satoru Team'
                                description='ID 3023565256'
                                image={require('./../assets/images/person-1.png')}
                                className="mb-3 border-0"

                                authorType="Admin"
                                authorName="Kathykatie"

                                agencyType="Agency code:"
                                agencyName="345"
                            />

                            <TeamCard
                                type="danger"
                                bgColor="#fff"
                                verified
                                shadow
                                title='Gojo Satoru Team'
                                description='ID 3023565256'
                                image={require('./../assets/images/person-1.png')}
                                className="mb-3"

                                authorType="Admin"
                                authorName="Kathykatie"

                                agencyType="Agency code:"
                                agencyName="345"
                            />

                            <TeamCard
                                bgColor="#fff"
                                verified
                                shadow
                                title='Gojo Satoru Team'
                                description='ID 3023565256'
                                image={require('./../assets/images/person-1.png')}
                                className="mb-3 border-0"

                                authorType="Admin"
                                authorName="Kathykatie"

                                agencyType="Agency code:"
                                agencyName="345"
                            />

                            <TeamCard
                                bgColor="#fff"
                                verified
                                shadow
                                title='Gojo Satoru Team'
                                description='ID 3023565256'
                                image={require('./../assets/images/person-1.png')}
                                className="mb-3 border-0"

                                authorType="Admin"
                                authorName="Kathykatie"

                                agencyType="Agency code:"
                                agencyName="345"
                            />
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}
export default Agency;