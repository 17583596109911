import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import { CoinYellow, DiamondYellow } from '../../utils/svg'
import { PageNameContext } from '../../context/context';
import { ROUTES } from '../../utils/app-constants';
import { useNavigate } from 'react-router-dom';

const ExchangeBody = ({ setShow }) => {
    const [page, setPage] = useContext(PageNameContext);
    const navigate = useNavigate()
    return (
        <div className='withdrawl-body d-flex flex-column justify-content-center'>
            <div className="devider" onClick={() => { setShow(false) }}>
                <span className="line"></span>
            </div>
            <h2>Are You Sure Want To Exchange?</h2>
            <div className='diamonds d-flex flex-column align-items-center justify-content-center mt-3 mx-auto'>
                <div className='amount-dimonds flex-custom-space-center'>
                    <span className='d-flex me-2'>
                        <DiamondYellow />
                    </span>
                    <p className='ms-1'>12,457,567</p>
                </div>
                <p className='d-flex align-items-center'>Diamonds To <span className='d-flex ms-1'><CoinYellow /></span>  10,000 Coins</p>
            </div>
            <div className='buttons d-flex mt-5 justify-content-center'>
                <Button className='cnfrm-btn' onClick={() => { navigate(ROUTES.exchange) }}>Confirm</Button>
                <Button className='cancel-btn' onClick={() => setShow(false)}>Cancel</Button>
            </div>
        </div>
    )
}

export default ExchangeBody
