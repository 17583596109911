import React from "react";

function RequestRow({ text1, text2 }) {
  return (
    <div className="w-100 d-flex ">
      <span className="w-50 fs-6 fw-700 ">{text1}</span>
      <span className="w-50 fs-6 fw-700 text-grey">{text2}</span>
    </div>
  );
}

export default RequestRow;
