import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminApplication from "../Pages/AdminApplication";
import AdminCenter from "../Pages/AdminCenter";
import AdminSuccess from "../Pages/AdminSuccess";
import Agency from "../Pages/Agency";
import AgencyApplication from "../Pages/AgencyApplication";
import AgencyApply from "../Pages/AgencyApply";
import AgencyDetails from "../Pages/AgencyDetails";
import AgencySuccess from "../Pages/AgencySuccess";
import BankTransfer from "../Pages/BankTransfer";
import BecomeAdmin from "../Pages/BecomeAdmin";
import BecomeAgency from "../Pages/BecomeAgency";
import BecomeHost from "../Pages/BecomeHost";
import BecomeReseller from "../Pages/BecomeReseller";
import BecomeSuperAdmin from "../Pages/BecomeSuperAdmin";
import Exchange from "../Pages/Exchange";
import ExchangeCustom from "../Pages/ExchangeCustom";
import HostCenter from "../Pages/HostCenter";
import HostRequest from "../Pages/HostRequest";
import HostSuccess from "../Pages/HostSuccess";
import MyAccount from "../Pages/MyAccounts";
import MyRequest from "../Pages/MyRequest";
import Reseller from "../Pages/Reseller";
import ResellerSuccess from "../Pages/ResellerSuccess";
import SuccessfullWithDrawl from "../Pages/SuccessfullWithDrawl";
import SuperAdminCenter from "../Pages/SuperAdminCenter";
import SuperAdminSuccess from "../Pages/SuperAdminSuccess";
import WalletHistory from "../Pages/WalletHistory";
import WithDrawl from "../Pages/Withdrawl";
import WithdrawlBundle from "../Pages/WithdrawlBundle";
import { ROUTES } from "../utils/app-constants";

const Navigations = () => {
  return (
    <>
      <Routes>
        <Route element={<BankTransfer />} path={ROUTES.bankTransfer}></Route>
        <Route element={<MyAccount />} path={ROUTES.myAccounts}></Route>


        <Route element={<Exchange />} path={ROUTES.exchange}></Route>
        <Route element={<ExchangeCustom />} path={ROUTES.exchangeCustom}></Route>
        <Route element={<WithdrawlBundle />} path={ROUTES.withdrawl}></Route>
        <Route element={<WithDrawl />} path={ROUTES.withdrawlCustom}></Route>
        <Route
          element={<SuccessfullWithDrawl />}
          path={ROUTES.withdrawlSuccess}
        ></Route>

        {/* <Route element={<BecomeReseller />} path={ROUTES.becomeReseller}></Route> */}
        <Route element={<BecomeReseller />} path={ROUTES.becomeReseller}></Route>
        <Route element={<Reseller />} path={ROUTES.reseller}></Route>
        <Route element={<ResellerSuccess />} path={ROUTES.resellerSuccess}></Route>
        <Route
          element={<AdminApplication />}
          path={ROUTES.adminApplication}
        ></Route>


        <Route element={<BecomeAgency />} path={ROUTES.becomeAgency}></Route>
        <Route element={<AgencySuccess />} path={ROUTES.agencysuccess}></Route>
        <Route element={<Agency />} path={ROUTES.agency}></Route>
        <Route element={<AgencyDetails />} path={ROUTES.agencyDetails}></Route>
        <Route element={<AgencyApply />} path={ROUTES.agencyApply}></Route>
        <Route
          element={<AgencyApplication />}
          path={ROUTES.agencyApplication}
        ></Route>

        <Route element={<BecomeHost />} path={ROUTES.becomeHost}></Route>
        <Route element={<HostRequest />} path={ROUTES.hostRequest}></Route>
        <Route element={<HostSuccess />} path={ROUTES.hostSuccess}></Route>
        <Route element={<HostCenter />} path={ROUTES.hostCenter}></Route>

        <Route element={<BecomeAdmin />} path={ROUTES.becomeAdmin}></Route>
        <Route element={<AdminCenter />} path={ROUTES.adminCenter}></Route>
        <Route element={<AdminSuccess />} path={ROUTES.adminSuccess}></Route>

        <Route element={<BecomeSuperAdmin />} path={ROUTES.becomeSuperAdmin}></Route>
        <Route element={<SuperAdminCenter />} path={ROUTES.superAdminCenter}></Route>
        <Route element={<SuperAdminSuccess />} path={ROUTES.superAdminSuccess}></Route>

        <Route element={<WalletHistory />} path={ROUTES.walletlHistory}></Route>
        <Route element={<MyRequest />} path={ROUTES.myRequest}></Route>


        {/* <Route element={<SelectRadio />} path={"/test"}></Route> */}

      </Routes>
    </>
  );
};

export default Navigations;
