import React from 'react'
import { Button } from 'react-bootstrap'

const ThemeRequestsBtn = ({ handleViewRequest, text }) => {
    return (
        <Button className='theme-request-btn' onClick={handleViewRequest}>{text}</Button>
    )
}

export default ThemeRequestsBtn
