import React from 'react'
import { Button } from 'react-bootstrap'
import { MdKeyboardBackspace } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../utils/app-constants'
import { SuccessHost } from '../utils/svg'
import { handleBackButton } from '../utils/helper'

const AdminSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation()

    return (
        <div className='host-success bg-light-pink'>
            <div className="top-bar flex-custom-space-between">
                <div className={"flex-custom-space-start"}>
                    <MdKeyboardBackspace size={24} onClick={() => handleBackButton(location, navigate)} />
                </div>
            </div>
            <div className='content p-2'>
                <div className=''>
                    <SuccessHost />
                </div>
                <div className='content-text w-100'>
                    <h2>We Are Reviewing Your Identity And will let you know Shortly</h2>
                    <p className='mt-3'>Dear User, We have successfully received your application. Once we review all your information, and if it is correct, it will be approved within the next 3 working days.</p>
                </div>
                <Button className='theme-btn mt-5' onClick={() => navigate(ROUTES.adminCenter)}>Done</Button>
            </div>

        </div>
    )
}

export default AdminSuccess
