import React from 'react'
import { DiamondYellow } from '../utils/svg'

function DemondBox({ className, amount, USD, onClick }) {
    return (
        <div className={`diamond-box d-flex flex-column ${className}`} onClick={onClick}>
            <div className="amount d-flex">
                <DiamondYellow />
                {amount}K
            </div>
            <div className="usd"><span className="me-1">{USD}</span> USD</div>
        </div>
    )
}

export default DemondBox
