import React, { useState } from "react";
import { Button, Image, Tab, Tabs } from "react-bootstrap";
import profile from "../assets/images/profile.png";
import { useNavigate } from "react-router-dom";
import TopBarCustom from "../components/TopBarCustom";
import SearchBar from "./../components/SearchBar";
import TeamCard from "./../components/TeamCard";
import RequestViewData from "../components/RequestViewData";
import { MdKeyboardBackspace } from "react-icons/md";


export default function HostRequest() {
  const navigate = useNavigate()
  const [adminTab, setAdminTab] = useState('video');

  const handleBackButton = () => {
    navigate(-1)
  }

  return (
    <div className='bg-pink'>
      <div className="my-request">
        {/* <TopBarCustom text={"My Requests"} onClick={handleBackButton} /> */}
        <div className='top-bar-middle px-3 d-flex w-100'>
          <MdKeyboardBackspace size={24} onClick={() => navigate(-1)} />
          <h1 className='text-center center-text w-100'>My Requests</h1>
        </div>
        <div className="scrollable-content ">
          <div className="my-4 container">
            <Tabs activeKey={adminTab} onSelect={(k) => setAdminTab(k)} className="agency-tabs host-tabs w-100 p-0 m-0">
              <Tab eventKey="video" title="Video - 5">
                <div className="mt-4 mb-2">
                  <SearchBar />
                </div>

                <div className="mt-3">
                  {Array.from({ length: 10 }).map(() => {
                    return <RequestViewData className={"mb-3"} imageUrl={profile} option={
                      <>
                        <div className="options-cross-done d-flex">
                          <div className="option-box"> <Image src={require('./../assets/images/cross.png')} /></div>
                          <div className="option-box ms-2"><Image src={require('./../assets/images/tickmark.png')} className="d-flex" /></div>
                        </div>
                      </>
                    } />;
                  })}
                </div>
                {/* <div className="mb-3" style={{ minHeight: '550px' }}>
                {[1, 1, 1]?.map(item => <TeamCard
                  // type="danger"
                  // bgColor="#fff"
                  verified
                  // shadow
                  title='Gojo Satoru Team'
                  description='ID 3023565256'
                  image={require('./../assets/images/person-1.png')}
                  className="border-0 mb-3"

                // authorType="Admin"
                // authorName="Kathykatie"

                // agencyType="Agency code:"
                // agencyName="345"

                // actionText="View Agencies"
                // actionFunc={() => alert('View Agencies')}
                >
                  <div className="operation-container">
                    <Button className="operation">
                      <Image src={require('./../assets/images/cross.png')} />
                    </Button>
                    <Button className="operation">
                      <Image src={require('./../assets/images/tickmark.png')} className="d-flex" />
                    </Button>
                  </div>
                </TeamCard>
                )}
              </div> */}
              </Tab>
              <Tab eventKey="audio" title="Audio - 7">
                <div className="mt-4 mb-2">
                  <SearchBar />
                </div>

                <div className="mb-3" style={{ minHeight: '550px' }}>
                  {[1, 1, 1]?.map(item => <TeamCard
                    // type="danger"
                    // bgColor="#fff"
                    verified
                    // shadow
                    title='Gojo Satoru Team'
                    description='ID 3023565256'
                    image={require('./../assets/images/person-1.png')}
                    className="border-0 mb-3"

                  // authorType="Admin"
                  // authorName="Kathykatie"

                  // agencyType="Agency code:"
                  // agencyName="345"

                  // actionText="View Agencies"
                  // actionFunc={() => alert('View Agencies')}
                  >
                    <div className="operation-container">
                      <Button className="operation">
                        <Image src={require('./../assets/images/cross.png')} />
                      </Button>
                      <Button className="operation">
                        <Image src={require('./../assets/images/tickmark.png')} className="d-flex" />
                      </Button>
                    </div>
                  </TeamCard>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}
