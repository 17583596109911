import React from 'react'

export default function ContentContainer({
    classes = '',
    backgroundColor = "",
    background = "",
    borderRadius = "",
    boxShadow = "",
    children,

}) {
    return (
        <div className={`ContentContainer ${classes}`} style={{
            borderRadius: borderRadius,
            backgroundColor: backgroundColor,
            background: background,
            boxShadow: boxShadow
        }}>
            {children}
        </div>
    )
}
