import React from 'react'

const CurrentBalance = ({ heading, text, className }) => {
    return (
        <div className={`current-balance flex-custom-space-between ${className}`}>
            <div className="heading">{heading}</div>
            <div className="text">{text}</div>
        </div>
    )
}

export default CurrentBalance
