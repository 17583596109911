import React from 'react'

const HistoryCard = ({ signal, image, dt }) => {
    return (
        <div className='shield flex-custom-space-between'>
            <div className='content d-flex'>
                {signal}
                <div className='text d-flex flex-column ms-3'>
                    <p>{dt?.text}</p>
                    <span className=''>{dt?.subtext}</span>
                </div>
            </div>
            <div className='coins-amount d-flex flex-column align-items-end'>
                <div className='d-flex amount-text'>
                    {image}
                    <span className='ms-2'>{dt?.amount}</span>
                </div>
                <p>{dt?.date}</p>
            </div>
        </div>
    )
}

export default HistoryCard
